<div class="w-full">
    <div class="w-full h-10 leading-10 bg-white border border-gray-300 text-gray-900 rounded-lg text-left px-4 cursor-pointer" matRipple [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
        <div *ngIf="selectedIcon != null">
            <mat-icon class="inline mr-2 align-middle">{{ selectedIcon.name }}</mat-icon>
            {{ selectedIcon.name }}
        </div>
        <span *ngIf="selectedIcon == null">
            Bitte wählen
        </span>
    </div>
    <mat-menu #menu="matMenu">
        <div class="p-2">
            <div>
                <input autocomplete="0" spellcheck="false" tabindex="1" placeholder="Suchbegriff hier eingeben" (click)="$event.stopPropagation()" matInput [(ngModel)]="searchString" (ngModelChange)="filterIconList()" />
            </div>
            <div class="flex flex-wrap w-full -mx-2">
                <div (click)="selectIcon(icon)" class="w-1/3 px-2 text-center mb-2 cursor-pointer" *ngFor="let icon of (filteredIconList$ | async)">
                    <button mat-icon-button>
                        <mat-icon>{{ icon.name }}</mat-icon>
                    </button><br>
                    <div class="text-xs text-gray-500 max-w-full overflow-hidden overflow-ellipsis">
                        {{ icon.name }}
                    </div>
                </div>
            </div>
        </div>

    </mat-menu>
</div>

