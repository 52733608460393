import {
    ComponentFactoryResolver,
    Directive,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewContainerRef
} from '@angular/core';
import ContentStructureField from '../api/model/contentStructureField';
import FieldTypeService from './fieldType.service';

@Directive({
    selector: '[fieldTypeConfig]',
})
export default class FieldTypeConfigDirective implements OnInit, OnChanges {
    @Input() contentStructureField: ContentStructureField;
    @Input() binding = {};
    private componentRef;

    constructor(public viewContainerRef: ViewContainerRef, private componentFactoryResolver: ComponentFactoryResolver, private fieldTypeService: FieldTypeService) { }

    ngOnInit() {
        let configComponent = this.fieldTypeService.getConfigRenderComponent(this.contentStructureField.type);
        if(configComponent) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(configComponent);
            const viewContainerRef = this.viewContainerRef;
            viewContainerRef.clear();
            this.componentRef = viewContainerRef.createComponent<any>(componentFactory);
            this.componentRef.instance['field'] = this.contentStructureField;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if(!this.componentRef) { return; }
        let configComponent = this.fieldTypeService.getConfigRenderComponent(this.contentStructureField.type);
        if(configComponent) {
            this.componentRef.instance['field'] = this.contentStructureField;;
        }
    }
}

