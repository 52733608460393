import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import ResultList from '../model/resultlist';
import Workspace from '../model/workspace';
import Helper from '../../util/helper';
import Translation from '../model/translation';

@Injectable()
export default class TranslationService {
    private endpoint: string;

    constructor(private http: HttpClient) {
        this.endpoint = environment.cmsUrl;
    }

    list(filter?: string, sortBy?: string, sortDir?: 'asc'|'desc', page = 1, limit = 10): Observable<ResultList<Translation>> {
        let query = Helper.serialize({page, limit, query: filter});
        return this.http.get<ResultList<Translation>>(this.endpoint + '/api/translations' + '?' + query);
    }

    create(translation: Translation): Observable<Translation> {
        return this.http.post<Translation>(this.endpoint + '/api/translations', translation);
    }

    update(translation: Translation): Observable<Translation> {
        return this.http.post<Translation>(this.endpoint + '/api/translations/' + translation.key, translation);
    }
}
