import { Component, OnInit } from '@angular/core';
import {ConfigFormBaseComponent} from '../config-form-base.component';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss']
})
export class TextComponent extends ConfigFormBaseComponent {
}
