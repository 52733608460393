import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import GlobalStorageService from '../helpers/globalStorage.service';
import {BehaviorSubject, Observable, of} from 'rxjs';
import AuthService from '../../api/auth.service';
import ContentStructure from '../../vendor/ng-lion-cms/api/model/contentStructure';
import {ContentModelService} from '../../vendor/ng-lion-cms/api/contentmodel/contentmodel.service';
import WorkspaceService from '../../vendor/ng-lion-cms/api/space/workspace.service';
import Workspace from '../../vendor/ng-lion-cms/api/model/workspace';
import {EditSpaceComponent, EditSpaceDialogModel} from '../dialogs/edit-space/edit-space.component';
import {MatDialog} from '@angular/material/dialog';
import NavigationService from "../services/navigation.service";
import NavigationEntryGroup from "../../model/navigationEntryGroup.model";
import {DeviceDetectorService} from "ngx-device-detector";
import Settings from "../../environments/settings";

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit {
  public opened: boolean;
  public mode: 'over'|'side'|'push' = 'side';
  public user$;

  private contentModelsSubject = new BehaviorSubject<ContentStructure[]>([]);
  public contentModels$ = this.contentModelsSubject.asObservable();

  private spacesSubject = new BehaviorSubject<Workspace[]>([]);
  public spaces$ = this.spacesSubject.asObservable();

  public selectedSpace$: Observable<Workspace>;

  public isWorkspaceAdmin = false;

  public navigationEntryGroups$: Observable<NavigationEntryGroup[]>;

  public title: string;

  constructor(private router: Router, private route: ActivatedRoute,
              private dialog: MatDialog,
              private device: DeviceDetectorService,
              private navigationService: NavigationService,
              public auth: AuthService, private spaceService: WorkspaceService,
              private contentModelService: ContentModelService,
              public store: GlobalStorageService) {
      this.user$ = auth.user$;
      this.selectedSpace$ = this.spaceService.selectedSpace$;
      this.navigationEntryGroups$ = this.navigationService.navigationEntries$;
      this.opened = this.device.isDesktop();
      this.title = Settings.adminTitle;

      if(!this.device.isDesktop()) {
        this.mode = 'push';
      }
      this.spaceService.list().subscribe(result => {
        this.spacesSubject.next(result.items);
        if(result.items.length > 0 && !localStorage.getItem('workspace')) {
          this.spaceService.setSelectedSpace(result.items[0]);
        } else if(localStorage.getItem('workspace') && result.items.length > 0) {
          for(let space of result.items) {
            if(space.id == localStorage.getItem('workspace')) {
              this.spaceService.setSelectedSpace(space);
              break;
            }
          }
        } else {
          this.spaceService.setSelectedSpace(null);
        }
      });

      this.selectedSpace$.subscribe(result => {
        if(result) {
          localStorage.setItem('workspace', result.id);
          this.isWorkspaceAdmin = this.auth.isRoot();
        }
      });
  }

  public setSelectedSpace(space: Workspace) {
    this.spaceService.setSelectedSpace(space);
  }

  ngOnInit(): void {
    this.selectedSpace$.subscribe(selectedSpace => {
      let data = {};
      if(selectedSpace) {
        data = { spaceId: selectedSpace.id };
      }
      this.navigationService.loadNavigation();
    });

  }

  goBack() {
    history.back();
  }

  logout() {
    this.auth.logout();
    this.router.navigate(['/auth/login']);
  }

  addSpace() {
    const dialogData = new EditSpaceDialogModel({
      name: '',
      description: ''
    });

    const dialogRef = this.dialog.open(EditSpaceComponent, {
      data: dialogData,
      panelClass: 'big-settings-dialog'
    });

    dialogRef.afterClosed().subscribe(space => {
      if(space) {
        const items = this.spacesSubject.getValue();
        items.push(space);
        this.spacesSubject.next(items);
        this.spaceService.setSelectedSpace(space);
      }
    });
  }

  public editWorkspace(space: Workspace) {
    const dialogData = new EditSpaceDialogModel(space);

    const dialogRef = this.dialog.open(EditSpaceComponent, {
      data: dialogData,
      panelClass: 'big-settings-dialog'
    });

    dialogRef.afterClosed().subscribe(data => {
      const spaces = this.spacesSubject.getValue();
      for(let i = 0; i < spaces.length; i++) {
        if(spaces[i].id == data.id) {
          Object.assign(spaces[i], data);
        }
      }
      this.spacesSubject.next(spaces);
      this.setSelectedSpace(data);
    });
  }

  public navClicked() {
    if(this.device.isMobile()) { this.opened = false; }
  }
}
