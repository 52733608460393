<div class="flex flex-wrap -mx-2">
    <div class="w-full px-2 lg:w-1/3" *ngIf="configFieldReference">
        <label>Zieltyp</label>
        <mat-select [(ngModel)]="configFieldReference.value" data-placeholder="Zieltyp" class="styled" [value]="getConfigFieldValue('referenceId')" (ngModelChange)="updateConfigField('referenceId', $event)">
            <mat-option disabled>Zieltyp</mat-option>
            <mat-option *ngFor="let cm of (contentModels$ | async)" [value]="cm.id">{{ cm.name }}</mat-option>
        </mat-select>
    </div>
    <div class="w-full px-2 lg:w-1/3" *ngIf="modeFieldReference">
        <label>Anzeige</label>
        <mat-select class="styled" [(ngModel)]="modeFieldReference.value" (ngModelChange)="updateConfigField('mode', $event)">
            <mat-option [value]="'inline'">Inline</mat-option>
            <mat-option [value]="'popup'">Popup</mat-option>
        </mat-select>
    </div>
</div>

    <div class="flex flex-wrap -mx-2">
        <div class="w-full p-2">
            <label>Max. Anzahl Referenzen</label>
            <input type="number" class="styled" matInput
                   [autocomplete]="'off'"
                   [value]="getConfigFieldValue('maxFiles')"
                   (change)="updateConfigField('maxFiles', $event.target.value)"
                   [placeholder]="'Anzahl Referenzen (maximal)'">
        </div>

        <div class="w-1/3 p-2">
            <div class="checkbox-wrapper pt-4" (click)="toggleConfigField('allowCreate')">
                <input type="checkbox" [checked]="getConfigFieldValue('allowCreate')"/>
                <span>Erlaube Erstellung</span>
            </div>
        </div>

        <div class="w-1/3 p-2">
            <div class="checkbox-wrapper" (click)="toggleConfigField('allowSelect')">
                <input type="checkbox" [checked]="getConfigFieldValue('allowSelect')" />
                <span>Erlaube Auswahl</span>
            </div>
        </div>
        <div class="w-1/3 p-2">
            <div class="checkbox-wrapper" (click)="toggleConfigField('sortable')">
                <input type="checkbox" [checked]="getConfigFieldValue('sortable')" />
                <span>Sortierbar</span>
            </div>
        </div>
    </div>

