<div class="flex flex-wrap -mx-2">
    <div class="w-full px-2 lg:w-1/2">
        <label>Erlaubte Dateiarten</label>
        <mat-select class="styled" (valueChange)="updateConfigField('fileTypes', $event)" [value]="getConfigFieldValue('fileTypes', [])" [multiple]="true">
            <mat-option [value]="'jpg'">JPEG</mat-option>
            <mat-option [value]="'png'">PNG</mat-option>
            <mat-option [value]="'mp4'">MP4</mat-option>
            <mat-option [value]="'webm'">WEBM</mat-option>
            <mat-option [value]="'pdf'">PDF</mat-option>
        </mat-select>
    </div>
    <div class="w-full px-2 lg:w-1/2">
        <label>Max. Dateigröße (in KB)</label>
        <input [value]="getConfigFieldValue('maxSize')" type="number" step="1" class="styled" matInput (change)="updateConfigField('maxSize', $event.target.value)"
               [autocomplete]="'off'"
               [placeholder]="'Maximalgröße (in KB)'">
    </div>
</div>
<div class="flex w-full">
    <div class="w-full">
        <label>Max. Anzahl Dateien</label>
        <input [value]="getConfigFieldValue('maxFiles')" type="number" step="1" class="styled" matInput (change)="updateConfigField('maxFiles', $event.target.value)"
               [autocomplete]="'off'"
               [placeholder]="'Anzahl Dateien (maximal)'">
    </div>
</div>
