import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import IconModel from "./icon.model";
import { MaterialIcons } from "./icon-data";
import {BehaviorSubject} from "rxjs";

@Component({
    selector: 'app-icon-selector',
    templateUrl: './icon-selector.component.html',
    styleUrls: ['./icon-selector.component.scss']
})
export class IconSelectorComponent implements OnInit {
    public searchString: string;
    public icons: IconModel[];

    private filteredIconListSubject = new BehaviorSubject<IconModel[]>([]);
    public filteredIconList$ = this.filteredIconListSubject.asObservable();

    @Input() selectedIconName: string;
    public selectedIcon: IconModel;

    @Output() iconChanged = new EventEmitter();

    constructor(private http: HttpClient) {
        this.icons = MaterialIcons;
        this.filteredIconListSubject.next(this.icons.slice(0, 8));
        for (let icon of this.icons) {
            if (icon.name == this.selectedIconName) {
                this.selectedIcon = icon;
                return;
            }
        }
    }

    ngOnInit(): void {
    }

    public filterIconList() {
        let iconList = [];
        let num = 0;
        for (let icon of this.icons) {
            let match = false;
            match = icon.name.indexOf(this.searchString) >= 0;
            if (!match) {
                for (let tag of icon.tags) {
                    if (tag.indexOf(this.searchString) >= 0) {
                        match = true;
                        break;
                    }
                }
            }
            if (match) {
                console.log(icon, this.searchString);
                num++;
                iconList.push(icon);
            }
            if (num > 12) {
                break;
            }
        }
        this.filteredIconListSubject.next(iconList);
    }

    public selectIcon(icon: IconModel): void {
        this.selectedIcon = icon;
        this.iconChanged.emit(icon.name);
    }

}
