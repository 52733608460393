import {Component, Inject, OnInit} from '@angular/core';
import ContentStructureField from '../../api/model/contentStructureField';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BehaviorSubject} from 'rxjs';
import ContentStructure from '../../api/model/contentStructure';
import {ContentModelService} from '../../api/contentmodel/contentmodel.service';

@Component({
  selector: 'app-content-model-field-dialog',
  templateUrl: './content-model-field-dialog.component.html',
  styleUrls: ['./content-model-field-dialog.component.scss']
})
export class ContentModelFieldDialogComponent implements OnInit {
  public field: ContentStructureField;
  private contentModelsSubject = new BehaviorSubject<ContentStructure[]>([]);
  public contentModels$ = this.contentModelsSubject.asObservable();
  public step = 1;

  constructor(public dialogRef: MatDialogRef<ContentModelFieldDialogComponent>,
              private contentModelService: ContentModelService,
              @Inject(MAT_DIALOG_DATA) public data: ContentModelFieldDialogModel) { }

  ngOnInit(): void {
    this.field = JSON.parse(JSON.stringify(this.data.field));
    if(this.field.id) {
      this.step = 2;
    }
    this.contentModelService.list(null, null, null, 1, 99).subscribe(result => {
      this.contentModelsSubject.next(result.items);
    });
  }

  public updateConfigField(name, value) {
    for(const config of this.field.config) {
      if(config.key == name) {
        config.value = value;
        return;
      }
    }
    console.log(name, value);

    this.field.config.push({
      key: name,
      value: value
    });
  }

  public toggleConfigField(name) {
    for(const config of this.field.config) {
      if(config.key == name) {
        config.value = !config.value;
        return;
      }
    }

    this.field.config.push({
      key: name,
      value: true
    });
  }

  public getConfigFieldValue(name, defaultValue = null) {
    for(const config of this.field.config) {
      if (config.key == name) {
        console.log(config.value);
        return config.value;
      }
    }
    return defaultValue;
  }
}

export class ContentModelFieldDialogModel {
  public constructor(public field: ContentStructureField) {
  }
}
