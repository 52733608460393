import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "src/environments/environment";
import {Observable, of, throwError} from "rxjs";
import {catchError, concatMap, delay, retryWhen, switchMap, take} from "rxjs/operators";
import ContentStructure from '../model/contentStructure';
import Helper from '../../util/helper';
import ResultList from '../model/resultlist';

@Injectable()
export class ContentModelService
{
    private endpoint: string;
    private contentModels: ContentStructure[];

    constructor(private http: HttpClient)
    {
        this.endpoint = environment.cmsUrl;
        let query = Helper.serialize({page: 1, limit: 99});
        this.http.get<ResultList<ContentStructure>>(this.endpoint + '/api/content-structures?' + query).pipe(
            retryWhen(errors => errors.pipe(delay(5000), take(100)))
        ).subscribe(result => {
            this.contentModels = result.items;
        });
    }

    list(filter: string, sortBy?: string, sortDir?: 'asc'|'desc', page = 1, limit = 30, additionalData?: any): Observable<ResultList<ContentStructure>> {
        let queryData = {page, limit, filter};
        if(additionalData) {
          queryData = Object.assign(queryData, additionalData);
        }
        let query = Helper.serialize(queryData);
        return this.http.get<ResultList<ContentStructure>>(this.endpoint + '/api/content-structures?' + query);
    }

    show(id: string): Observable<ContentStructure> {
        return this.http.get<ContentStructure>(this.endpoint + '/api/content-structures/' + id).pipe(
          switchMap(result => {
            for(let i = 0; i < result.fields.length; i++) {
              if(typeof result.fields[i].config == 'string') {
                result.fields[i].config = JSON.parse(result.fields[i].config as any);
              }
            }
            return of(result);
          })
        );
    }

    update(contentModel: ContentStructure): Observable<boolean> {
        return this.http.put<any>(this.endpoint + '/api/content-structures/' + contentModel.id, contentModel).pipe(
          switchMap(result => {
            return of(result);
          })
        );
    }

    create(contentModel: ContentStructure): Observable<ContentStructure> {
        return this.http.post<ContentStructure>(this.endpoint + '/api/content-structures', contentModel).pipe(
          switchMap(result => {
            for(let i = 0; i < result.fields.length; i++) {
              if(typeof result.fields[i].config == 'string') {
                result.fields[i].config = JSON.parse(result.fields[i].config as any);
              }
            }
            return of(result);
          })
        );
    }

    delete(contentModel: ContentStructure): Observable<boolean> {
        return this.http.delete(this.endpoint + '/api/content-structures/' + contentModel.id).pipe(
            switchMap(result => of(true)),
            catchError(error => of(false))
        );
    }

    contentModelByApiName(apiName: string): Observable<ContentStructure> {
        return this.list(null, null, null, 1, 1, {
          apiName: apiName
        }).pipe(switchMap(result => this.show(result.items[0].id)));
    }

    contentModelById(id: string): ContentStructure|null {
        for(let cm of this.contentModels) {
            if(cm.id == id) {
                return cm;
            }
        }
        return null;
    }
}
