<div class="w-full p-4 bg-gray-100 rounded-lg">
    <label>{{ field.name }}</label>
    <div class="flex flex-wrap -mx-1">
        <div *ngFor="let medium of content[field.apiName]" class="media-wrapper px-1">
            <div class="media aspect-1-1 rounded-lg" (click)="showMedialib()">
                <div class="overlay full">
                    <img *ngIf="isImage()" [src]="medium.file" alt="" />
                </div>
            </div>
        </div>
    </div>
    <div (click)="showMedialib()" class="btn sm primary mt-4">Medien auswählen</div>

</div>
