import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export default class GlobalStorageService {
  private configSubject = new BehaviorSubject<{ [x: string]: any }>({});
  public config$ = this.configSubject.asObservable();

  public setConfig(name: string, value: string) {
    let config = this.configSubject.getValue();
    config[name] = value;
    this.configSubject.next(config);
  }

  public getConfig() {
    return this.config$;
  }

  public overrideConfig(config: any) {
    this.configSubject.next(config);
  }
}
