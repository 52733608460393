import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup} from '@angular/forms';
import LanguageService from '../../../vendor/ng-lion-cms/service/language.service';
import Translation from '../../../vendor/ng-lion-cms/api/model/translation';
import WorkspaceService from '../../../vendor/ng-lion-cms/api/space/workspace.service';

@Component({
  selector: 'app-translation-dialog',
  templateUrl: './translation-dialog.component.html',
  styleUrls: ['./translation-dialog.component.scss']
})
export class TranslationDialogComponent implements OnInit {
  public translation: Translation;
  public languages: string[] = [];
  constructor(@Inject(MAT_DIALOG_DATA) private data: Translation, private dialogRef: MatDialogRef<TranslationDialogComponent>, private fb: FormBuilder, private workspace: WorkspaceService) { }

  ngOnInit(): void {

    this.languages = this.workspace.getSelectedSpaceSnapshot().languages;
    if(this.data) {
      this.translation = JSON.parse(JSON.stringify(this.data));
    } else {
      let translations = {};
      for(let lang of this.languages) {
        translations[lang] = '';
      }
      this.translation = {
        key: '',
        domain: null,
        translations: translations
      };
    }



  }

  public save() {
    this.dialogRef.close(this.translation);
  }

}
