import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-medialib-settings-dialog',
  templateUrl: './medialib-settings-dialog.component.html',
  styleUrls: ['./medialib-settings-dialog.component.scss']
})
export class MedialibSettingsDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
