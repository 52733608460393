<div mat-dialog-title>{{ space.id ? space.name + ' bearbeiten' : 'Neue Welt erstellen' }}</div>

  <div mat-dialog-content>
    <form [formGroup]="form">
      <mat-label>Name</mat-label>
      <mat-form-field>
        <input type="text" matInput formControlName="name" />
      </mat-form-field>
      <mat-label>Beschreibung</mat-label>
      <mat-form-field>
        <textarea matInput formControlName="description"></textarea>
      </mat-form-field>
      <div class="my-2">
        <mat-checkbox formControlName="pagebuilder">Pagebuilder aktivieren?</mat-checkbox>
      </div>

      <mat-label>Hostname</mat-label>
      <mat-form-field>
        <input matInput type="text" placeholder="Adresse der Website" formControlName="host" />
      </mat-form-field>

      <mat-label>Unterstützte Sprachen</mat-label>
      <mat-form-field>
        <mat-select formControlName="languages" multiple>
          <mat-option *ngFor="let lang of availableLanguages" [value]="lang">{{ lang.toUpperCase() }}</mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </div>

  <div mat-dialog-actions>
    <div class="w-full text-right">
      <button mat-flat-button (click)="onDismiss()">Abbrechen</button>
      <button color="primary" mat-flat-button [disabled]="form?.invalid || (saving$ | async)" (click)="onConfirm()">Speichern</button>
    </div>
  </div>
