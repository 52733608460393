import {Component, Inject, OnInit} from '@angular/core';
import Page from '../../../vendor/ng-lion-cms/api/model/page';
import LanguageService from '../../../vendor/ng-lion-cms/service/language.service';

@Component({
  selector: 'app-add-page',
  templateUrl: './add-page.component.html',
  styleUrls: ['./add-page.component.scss']
})
export class AddPageComponent implements OnInit {
  public page: Page;
  public languages = [];

  constructor(private languageService: LanguageService) {
    this.page = {
      name: '',
      url: '',
      localization: null
    } as Page;
    this.languages = this.languageService.getEnabledLanguages();
  }

  ngOnInit(): void {
  }

}
