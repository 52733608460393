import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {FormsModule} from '@angular/forms';
import {ContentComponent} from './content.component';
import {ServicesModule} from '../../service/services.module';
import {MatTabsModule} from '@angular/material/tabs';


@NgModule({
    declarations: [ContentComponent],
    imports: [
        CommonModule,
        FormsModule,
        MatSelectModule,
        MatInputModule,
        ServicesModule,
        MatTabsModule
    ],
    exports: [
        ContentComponent
    ]
})
export class ContentModule { }
