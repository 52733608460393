<div mat-dialog-title>Übersetzung</div>

<mat-dialog-content>
    <mat-form-field class="mt-8">
        <mat-label>Schlüssel</mat-label>
        <input type="text" matInput [(ngModel)]="translation.key" />
    </mat-form-field>
    <mat-form-field>
        <mat-label>Domain</mat-label>
        <input type="text" matInput [(ngModel)]="translation.domain" />
    </mat-form-field>
    <mat-form-field *ngFor="let lang of languages">
        <mat-label>Text ({{ lang.toUpperCase() }})</mat-label>
        <textarea matInput [(ngModel)]="translation.translations[lang]"></textarea>
    </mat-form-field>
</mat-dialog-content>

<div mat-dialog-actions class="w-full">
    <div class="w-full text-right">
        <div mat-dialog-close class="btn bg-gray-200 sm mr-4" matRipple>Abbrechen</div>
        <div (click)="save()" class="btn primary sm" matRipple>Speichern</div>
    </div>
</div>
