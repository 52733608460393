import { Component, OnInit } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import Role from '../../../vendor/ng-lion-cms/api/model/role';
import RoleService from '../../../vendor/ng-lion-cms/api/role/role.service';
import WorkspaceService from '../../../vendor/ng-lion-cms/api/space/workspace.service';
import Workspace from '../../../vendor/ng-lion-cms/api/model/workspace';
import Invitation from '../../../vendor/ng-lion-cms/api/model/invitation';

@Component({
  selector: 'app-invitation-dialog',
  templateUrl: './invitation-dialog.component.html',
  styleUrls: ['./invitation-dialog.component.scss']
})
export class InvitationDialogComponent implements OnInit {
  private rolesSubject = new BehaviorSubject<Role[]>([]);
  public roles$ = this.rolesSubject.asObservable();

  public currentWorkspace: Workspace;

  public selectedRole: Role;
  public email: string;

  public invitation: Invitation;

  constructor(private roleService: RoleService, private workspaceService: WorkspaceService) { }

  ngOnInit(): void {
    this.currentWorkspace = this.workspaceService.getSelectedSpaceSnapshot();
    this.roleService.list().subscribe(result => {
      this.rolesSubject.next(result.items);
    });
  }

  generate() {
    let url = location.protocol + '//' + location.host + '/invitation/:token';
    this.workspaceService.invite(this.selectedRole, this.email, url).subscribe(result => {
      this.invitation = result;
    });
  }

}
