<div mat-dialog-title>
  Feld {{ field.id ? 'bearbeiten' : 'hinzufügen' }}
</div>
<div mat-dialog-content>
  <div *ngIf="step == 1">
    <p>Bitte wähle einen Typ für das Feld aus.</p>
    <div class="flex flex-wrap -mx-1">
      <div class="w-1/2 p-1">
        <div class="field-type" [class.active]="field.type == 'text'" (click)="field.type = 'text'">
            <div class="icon">
              <span class="iconify" data-icon="bi:input-cursor-text"></span>
            </div>
            <div class="name">
              Textfeld
            </div>
            <div class="description">Einzeiliges Textfeld</div>
        </div>
      </div>
      <div class="w-1/2 p-1">
        <div class="field-type" [class.active]="field.type == 'textarea'" (click)="field.type = 'textarea'">
          <div class="icon">
            <span class="iconify" data-icon="akar-icons:text-align-left"></span>
          </div>
          <div class="name">
            Textbereich
          </div>
          <div class="description">Mehrzeiliges Textfeld</div>
        </div>
      </div>
      <div class="w-1/2 p-1">
        <div class="field-type" [class.active]="field.type == 'richtext'" (click)="field.type = 'richtext'">
          <div class="icon">
            <span class="iconify" data-icon="fluent:clipboard-text-ltr-20-regular"></span>
          </div>
          <div class="name">
            Formatierter Text
          </div>
          <div class="description">Text mit Formatierungen</div>
        </div>
      </div>
      <div class="w-1/2 p-1">
        <div class="field-type" [class.active]="field.type == 'media'" (click)="field.type = 'media'">
          <div class="icon">
            <span class="iconify" data-icon="akar-icons:image"></span>
          </div>
          <div class="name">
            Medium
          </div>
          <div class="description">Bild, Video oder Dokument einbinden</div>
        </div>
      </div>
      <div class="w-1/2 p-1">
        <div class="field-type" [class.active]="field.type == 'medialist'" (click)="field.type = 'medialist'">
          <div class="icon">
            <span class="iconify" data-icon="clarity:image-gallery-line"></span>
          </div>
          <div class="name">
            Medien
          </div>
          <div class="description">Mehrere Bilder, Videos oder Dokumente einbinden</div>
        </div>
      </div>
      <div class="w-1/2 p-1">
        <div class="field-type" [class.active]="field.type == 'dropdown'" (click)="field.type = 'dropdown'">
          <div class="icon">
            <span class="iconify" data-icon="mdi:form-dropdown"></span>
          </div>
          <div class="name">
            Auswahl-Liste
          </div>
          <div class="description">Auswahl aus mehreren Einträgen</div>
        </div>
      </div>
      <div class="w-1/2 p-1">
        <div class="field-type" [class.active]="field.type == 'reference'" (click)="field.type = 'reference'">
          <div class="icon">
            <span class="iconify" data-icon="carbon:data-table-reference"></span>
          </div>
          <div class="name">
            Referenz
          </div>
          <div class="description">Ein anderes Objekt referenzieren</div>
        </div>
      </div>
      <div class="w-1/2 p-1">
        <div class="field-type" [class.active]="field.type == 'referencelist'" (click)="field.type = 'referencelist'">
          <div class="icon">
            <span class="iconify" data-icon="icon-park-outline:one-to-many"></span>
          </div>
          <div class="name">
            Referenzen
          </div>
          <div class="description">Mehrere andere Objekte referenzieren</div>
        </div>
      </div>
      <div class="w-1/2 p-1">
        <div class="field-type" [class.active]="field.type == 'date'" (click)="field.type = 'date'">
          <div class="icon">
            <span class="iconify" data-icon="uiw:date"></span>
          </div>
          <div class="name">
            Datum
          </div>
          <div class="description">Datum auswählen</div>
        </div>
      </div>
      <div class="w-1/2 p-1">
        <div class="field-type" [class.active]="field.type == 'time'" (click)="field.type = 'time'">
          <div class="icon">
            <span class="iconify" data-icon="bx:bx-time-five"></span>
          </div>
          <div class="name">
            Uhrzeit
          </div>
          <div class="description">Uhrzeit auswählen</div>
        </div>
      </div>
      <div class="w-1/2 p-1">
        <div class="field-type" [class.active]="field.type == 'checkbox'" (click)="field.type = 'checkbox'">
          <div class="icon">
            <span class="iconify" data-icon="fluent:checkbox-checked-16-regular"></span>
          </div>
          <div class="name">
            Auswahl-Box
          </div>
          <div class="description">An- und abwählbare Box</div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="step == 2">
    <mat-tab-group>
      <mat-tab label="Grundeinstellungen">
        <div class="flex flex-wrap">
          <div class="w-full lg:w-1/2 px-2 mt-3">
            <mat-label>Anzeigename</mat-label>
            <mat-form-field>
              <input type="text" matInput
                     [autocomplete]="'off'"
                     [(ngModel)]="field.name"
                     [placeholder]="'Name (z.B. Post-Titel, Titelbild, ...)'">
            </mat-form-field>
          </div>
          <div class="w-full lg:w-1/2 px-2 mt-3">
            <mat-label>API-Name</mat-label>
            <mat-form-field>
              <input type="text" matInput
                     [autocomplete]="'off'"
                     [(ngModel)]="field.apiName"
                     [placeholder]="'API Name (z.B. post_title, title_image...)'">
            </mat-form-field>
          </div>
          <div class="w-full lg:w-1/3 mt-3">
            <div class="p-3">
              <mat-slide-toggle [(ngModel)]="field.showInList">In Liste anzeigen</mat-slide-toggle>
            </div>
          </div>
          <div class="w-full lg:w-1/3 mt-3">
            <div class="p-3">
              <mat-slide-toggle [(ngModel)]="field.mandatory">Pflichtfeld</mat-slide-toggle>
            </div>
          </div>
          <div class="w-full lg:w-1/3 mt-3">
            <div class="p-3">
              <mat-slide-toggle [(ngModel)]="field.internationalizable">Internationalisierbar</mat-slide-toggle>
            </div>
          </div>
          <div class="w-full lg:w-1/3 mt-3">
            <mat-label>Priorität</mat-label>
            <mat-form-field>
              <input type="number" matInput
                     [autocomplete]="'off'"
                     [(ngModel)]="field.priority">
            </mat-form-field>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Feldeinstellungen">
        <div fieldTypeConfig [contentStructureField]="field"></div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<div mat-dialog-actions>
  <div class="w-full text-right px-4">
    <div *ngIf="step == 2" (click)="step = 1" matRipple class="btn sm mr-4">
      Feld-Typ ändern
    </div>
    <div *ngIf="step < 2" (click)="step = 2" matRipple class="btn primary sm">
      Weiter
    </div>
    <div *ngIf="step == 2" [mat-dialog-close]="field" matRipple class="btn primary sm">
      Speichern
    </div>
  </div>

</div>
