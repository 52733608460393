<div class="flex flex-wrap">
    <div class="w-full px-2 lg:w-1/2">
        <mat-label>Erlaubte Dateiarten</mat-label>
        <mat-form-field>
            <mat-select [(ngModel)]="initialValues['fileTypes']" (ngModelChange)="updateConfigField('fileTypes', $event)" [value]="getConfigFieldValue('fileTypes', [])" [multiple]="true">
                <mat-option [value]="'jpg'">JPEG</mat-option>
                <mat-option [value]="'png'">PNG</mat-option>
                <mat-option [value]="'mp4'">MP4</mat-option>
                <mat-option [value]="'webm'">WEBM</mat-option>
                <mat-option [value]="'pdf'">PDF</mat-option>
            </mat-select>
        </mat-form-field>

    </div>
    <div class="w-full px-2 lg:w-1/2">
        <mat-label>Max. Dateigröße (in KB)</mat-label>
        <mat-form-field>
            <input [(ngModel)]="initialValues['maxSize']" [value]="getConfigFieldValue('maxSize')" type="number" step="1" matInput (change)="updateConfigField('maxSize', $event.target.value)"
                   [autocomplete]="'off'"
                   [placeholder]="'Maximalgröße (in KB)'">
        </mat-form-field>

    </div>
</div>
