import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {QuillModule} from 'ngx-quill';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRippleModule} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import FieldTypeDirective from './fieldType.directive';
import FieldTypeConfigDirective from './fieldTypeConfig.directive';
import {MatDialogModule} from '@angular/material/dialog';
import FieldTypeService from './fieldType.service';



@NgModule({
    declarations: [FieldTypeDirective, FieldTypeConfigDirective],
    imports: [
        CommonModule,
        FormsModule,
        MatInputModule,
        QuillModule,
        MatDialogModule,
        MatSelectModule,
        MatCheckboxModule,
        MatRippleModule,
        MatIconModule,
        MatButtonModule
    ],
    providers: [FieldTypeService],
    exports: [
        FieldTypeConfigDirective, FieldTypeDirective
    ]
})
export class ServicesModule { }
