export default class Helper {
  public static toQueryString(obj: any, prefix?: string): string {
    let str = [],
      p;
    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        let k = prefix ? prefix + "[" + p + "]" : p,
          v = obj[p];
        str.push((v !== null && typeof v === "object") ?
          this.toQueryString(v, k) :
          k + "=" + encodeURIComponent(v));
      }
    }
    return str.join("&").replace('&&', '&');
  }

  public static cartesianProduct(a): Array<Array<any>> { // a = array of array
    let i, j, l, m, a1, o = [];
    if(!a || a.length == 0) return a;

    a1 = a.splice(0, 1)[0]; // the first array of a
    a = this.cartesianProduct(a);
    for (i = 0, l = a1.length; i < l; i++) {
      if (a && a.length) for (j = 0, m = a.length; j < m; j++)
        o.push([a1[i]].concat(a[j]));
      else
        o.push([a1[i]]);
    }
    return o;
  }

  public static slugify(text: string): string {
    return text
      .toString()
      .normalize( 'NFD' )                   // split an accented letter in the base letter and the acent
      .replace( /[\u0300-\u036f]/g, '' )   // remove all previously split accents
      .toLowerCase()
      .trim()
      .replace(/\s+/g, '-')
      .replace(/[^\w\-]+/g, '')
      .replace(/\-\-+/g, '-');
  };

  private static chunkArray(arr: Array<number>) {
    let result: Array<Array<number>> = [];
    let lastVal = arr.splice(0, 1)[0];
    let curArr: Array<number> = [];
    curArr.push(lastVal);
    while(arr.length > 0) {
      let curVal = arr.splice(0, 1)[0];
      if(lastVal == null) {
        lastVal = curVal;
      } else if(Math.abs(curVal - lastVal) > 1) {
        result.push(JSON.parse(JSON.stringify(curArr)));
        curArr.length = 0;
        lastVal = null;
      } else {
        curArr.push(curVal);
        lastVal = curVal;
      }
    }
    if(curArr.length > 0) {
      result.push(JSON.parse(JSON.stringify(curArr)));
    }
    return result;
  }

  public static circularReplacer() {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  }

  public static getInitials(name: string) {
    return name.split(' ').filter((str) => {
      return str.length > 0;
    }).map((str) => {
      return str.substr(0, 1).toUpperCase();
    }).join('');
  }

  public static randomColorByName(name: string) {
    let colors = [
      'bg-green-600', 'bg-blue-600', 'bg-purple-600',
      'bg-orange-600', 'bg-yellow-600', 'bg-teal-600',
      'bg-red-600', 'bg-indigo-600', 'bg-pink-600'
    ];

    let sum = 0;
    for(let i = 0; i < name.length; i++) {
      sum += name.charCodeAt(i);
    }

    const offset = sum % colors.length;

    return colors[offset];
  }
}
