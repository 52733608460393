import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextComponent } from './text/text.component';
import {FormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import { RichtextComponent } from './richtext/richtext.component';
import {QuillModule} from 'ngx-quill';
import { MediaComponent } from './media/media.component';
import { MedialistComponent } from './medialist/medialist.component';
import {MatDialogModule} from '@angular/material/dialog';
import { ReferenceComponent } from './reference/reference.component';
import { ReferencelistComponent } from './referencelist/referencelist.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { TextareaComponent } from './textarea/textarea.component';
import {MatRippleModule} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {ContentModule} from '../components/content/content.module';
import {ServicesModule} from '../service/services.module';
import { DateComponent } from './date/date.component';
import {NgxMatDateFormats, NgxMatDatetimePickerModule, NgxMatTimepickerModule} from '@angular-material-components/datetime-picker';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {ComponentsModule} from '../components/components.module';

@NgModule({
  declarations: [TextComponent, RichtextComponent, MediaComponent, MedialistComponent, ReferenceComponent, ReferencelistComponent, CheckboxComponent, DropdownComponent, TextareaComponent, DateComponent],
    imports: [
        CommonModule,
        FormsModule,
        MatInputModule,
        QuillModule,
        MatDialogModule,
        MatSelectModule,
        MatCheckboxModule,
        MatRippleModule,
        MatIconModule,
        MatButtonModule,
        ContentModule,
        ServicesModule,
        MatDatepickerModule,
        NgxMatTimepickerModule,
        NgxMatDatetimePickerModule,
        MatSlideToggleModule,
        ComponentsModule
    ]
})
export class FormComponentsModule { }
