import {AfterViewInit, Component, NgZone, OnInit, ViewChild} from '@angular/core';
import {FormBaseComponent} from '../form-base.component';
import {QuillEditorComponent, QuillModules} from 'ngx-quill';
import {MediaDialogComponent, MediaDialogModel} from '../../components/media-dialog/media-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import Medium from '../../api/model/medium';
import * as QuillNamespace from 'quill';

import Quill from 'quill';
import BlotFormatter, {Options} from 'quill-blot-formatter';
const Image = Quill.import('formats/image');
const ATTRIBUTES = [
  'alt',
  'height',
  'width',
  'class', // this will allow the class attribute on the image tag
  'style'
];

Quill.register('modules/blotFormatter', BlotFormatter);

const Link = Quill.import('formats/link');

class MyLink extends Link {
  static create(value) {
    let node = super.create(value);
    node.removeAttribute('target');
    return node;
  }
}

Quill.register(MyLink);

class CustomImage extends Image {
  static formats(domNode) {
    return ATTRIBUTES.reduce((formats, attribute) => {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }
  format(name, value) {
    if (ATTRIBUTES.indexOf(name) > -1) {
      if (value) {
        (this as any).domNode.setAttribute(name, value);
      } else {
        (this as any).domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}

Quill.register({
  // ... other formats
  'formats/image': CustomImage
});

@Component({
  selector: 'app-richtext',
  templateUrl: './richtext.component.html',
  styleUrls: ['./richtext.component.scss']
})
export class RichtextComponent extends FormBaseComponent implements AfterViewInit {
  @ViewChild("editor") editor: QuillEditorComponent;
  private quillEditor;
  public editorOptions = {
    blotFormatter: {
      align: {
        attribute: 'data-align',
        aligner: {
          applyStyle: true,
        },
      }
    } as Options,
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],

        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],

        ['clean'],
        [{ 'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],
        ['link']
      ],
      handlers: {}
    }
    /*toolbar: ,*/
    /*[{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
      [{ 'direction': 'rtl' }],                         // text direction

      [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'font': [] }],


      ['clean'],                                         // remove formatting button

      ['link', 'image', 'video']                         // link and image, video*/
  };

  public constructor(private dialog: MatDialog, private ngZone: NgZone) {
    super();

  }

  ngAfterViewInit() {
    if(this.getFieldConfigValue('allowImages')) {
      this.editorOptions.toolbar.container.push(['image']);
      this.editorOptions.toolbar.handlers['image'] = this.addImage.bind(this);
    }
  }

  public addImage() {
    this.ngZone.run(() => {
      let data = new MediaDialogModel([null], false, ['jpg', 'png']);

      const dialogRef = this.dialog.open(MediaDialogComponent, {
        data: data,
        panelClass: 'medialib-dialog'
      });

      dialogRef.afterClosed().subscribe(result => {
        if(result) {
          if(this.quillEditor) {
            const content = document.createElement('img');
            content.src = (result as Medium).file;
            content.alt = (result as Medium).description;

            const index = this.quillEditor?.getSelection()?.index;

            this.quillEditor.clipboard.dangerouslyPasteHTML(index, content.outerHTML);
          }
        }
      });
    });
  }

  public editorCreated(quillEditor) {
    console.log(quillEditor);
    this.quillEditor = quillEditor;
  }
}
