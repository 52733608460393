<div mat-dialog-title>Einladung verschicken</div>
<div mat-dialog-content>
    <div *ngIf="invitation == null">
        <div class="mb-4">
            Welche Rolle soll der neue Benutzer in deinem Workspace <em>{{ currentWorkspace.name }}</em> haben?
        </div>

        <input class="styled" [(ngModel)]="email" type="email" placeholder="Email-Adresse" />

        <div class="role" (click)="selectedRole = role" *ngFor="let role of (roles$ | async)" [class.selected]="role == selectedRole" matRipple>
            <div class="title">{{ role.name }}</div>
            <div class="description">
                {{ role.description }}
            </div>
        </div>
    </div>
    <div *ngIf="invitation">
        <div class="text-center mb-4">
            Die Einladung wurde erstellt und an die angegebene Email-Adresse verschickt.<br>
            Der Einladungscode lautet:
        </div>
        <div class="text-center mb-4">
            <div class="inline-block border-4 border-gray-200 px-4 py-2 text-3xl font-bold font-mono">
                {{ invitation.token }}
            </div>
        </div>
        <div class="text-center">
            <div class="btn primary sm" mat-dialog-close>Fenster schließen</div>
        </div>
    </div>

</div>

<div mat-dialog-actions class="w-full">
    <div class="w-full text-right" *ngIf="invitation == null">
        <div *ngIf="email && selectedRole" (click)="generate()" class="btn primary sm" matRipple>Einladungslink erstellen</div>
        <div *ngIf="!(email && selectedRole)" class="btn bg-gray-200 sm">Einladungslink erstellen</div>
    </div>

</div>
