<div mat-dialog-title>Seite hinzufügen</div>
<div mat-dialog-content>
  <label>Name</label>
  <input type="text" placeholder="z.B. Über uns" class="styled" matInput [(ngModel)]="page.name" />

  <label>URL</label>
  <input type="text" placeholder="z.B. /home" class="styled" matInput [(ngModel)]="page.url" />

  <label>Sprache</label>
  <mat-select class="styled" [(ngModel)]="page.localization">
    <mat-option [value]="null">Alle Sprachen</mat-option>
    <mat-option *ngFor="let lang of languages" [value]="lang">{{ lang.toUpperCase() }}</mat-option>
  </mat-select>
</div>

<div mat-dialog-actions>
  <div class="w-full text-right">
    <div matRipple mat-dialog-close class="py-2 px-4 text-gray-700 font-bold cursor-pointer inline-block ml-3 rounded">
      Abbrechen
    </div>
    <div matRipple [mat-dialog-close]="page" class="py-2 px-4 bg-blue-500 text-white font-bold cursor-pointer inline-block ml-3 rounded">
      Speichern
    </div>
  </div>
</div>
