import {Injectable} from '@angular/core';
import MediumModel from '../../../../model/crm/medium.model';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import Pagination from '../../../../model/crm/pagination';
import {environment} from '../../../../environments/environment';
import {catchError, switchMap} from 'rxjs/operators';

@Injectable()
export default class MediaService {
    public constructor(private http: HttpClient) {
    }

    public list(query: string, page?: number, limit?: number): Observable<Pagination<MediumModel>> {
        page = page || 1;
        limit = limit || 20;
        return this.http.get<Pagination<MediumModel>>(environment.cmsUrl + '/api/media?page=' + page + '&limit=' + limit + '&query=' + encodeURIComponent(query));
    }

    public show(id: number) {

    }

    public update(medium: MediumModel) {

    }

    public delete(medium: MediumModel) {

    }

    public create(medium: MediumModel): Observable<MediumModel> {
        return this.http.post<MediumModel>(environment.cmsUrl + '/api/media', medium);
    }
}
