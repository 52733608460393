import {BehaviorSubject, Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../environments/environment';
import {catchError, switchMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import Helper from '../util/helper';
import User from "../vendor/ng-lion-cms/api/model/user";

@Injectable()
export default class AuthService {
  private userSubject = new BehaviorSubject<User>(null);
  public user$ = this.userSubject.asObservable();

  get token() {
    return localStorage.getItem('sso_token');
  }

  set token(token: string) {
    localStorage.setItem('sso_token', token);
  }

  public constructor(private http: HttpClient) {
  }

  public login(username: string, password: string): Observable<{token: string}> {
    let url = environment.cmsUrl + '/auth/login_check';
    return this.http.post<{token: string}>(url, {
      username: username,
      password: password
    });
  }
  public ssoConnect(): Observable<{ url: string }> {
    let successUrl = location.href;
    let failureUrl = location.href;
    let q = {
      redirect_success: successUrl,
      redirect_failure: failureUrl
    };

    let url = environment.cmsUrl + '/users/connect?' + Helper.toQueryString(q);
    return this.http.get<{url: string}>(url);
  }

  public loadUser(): Observable<User> {
    return this.http.get<User>(environment.cmsUrl + '/api/users/me').pipe(switchMap(user => {
      this.userSubject.next(user);
      return of(user);
    }));
  }

  public getConnectionToken() {
    return localStorage.getItem('sso_token');
  }

  public isRoot() {
    console.log(this.userSubject.getValue().roles);
    for(let role of this.userSubject.getValue().roles) {
      if(role.root) { return true; }
    }
    return false;
  }

  public logout() {
    this.userSubject.next(null);
    this.token = null;

  }

  public register(username: string, password: string, email: string): Observable<boolean> {
    return this.http.post(environment.cmsUrl + '/api/users/register', {
      username: username,
      password: password,
      email: email
    }).pipe(
        switchMap(() => of(true)),
        catchError(() => of(false))
    );
  }

  public confirmRegistration(token: string): Observable<boolean> {
    return this.http.post(environment.cmsUrl + '/api/users/confirm-registration', {
      token: token
    }).pipe(
        switchMap(() => of(true)),
        catchError(() => of(false))
    );
  }

  public forgotPassword(email: string): Observable<boolean> {
    return this.http.post(environment.cmsUrl + '/api/users/forgot-password', { email }).pipe(
        switchMap(() => of(true)),
        catchError(() => of(false))
    );
  }

  public resetPassword(token: string, password: string): Observable<boolean> {
    return this.http.post(environment.cmsUrl + '/api/users/reset-password', { token, password }).pipe(
        switchMap(() => of(true)),
        catchError(() => of(false))
    );
  }
}
