import {localEnv} from './local.env';

export const environment = {
  production: false,
  xdebug: true,
  locale: 'de',
  ssoUrl: localEnv.ssoUrl,
  cmsUrl: localEnv.cmsUrl,
  defaultConfig: {
    showBack: false,
    burgerColor: 'text-black',
    title: ''
  },
  loginMode: 'classic',
  vapid_public_key: 'BJ8ZBSu0uiHnKgTX9WQV86ri8rAERmzj88C34udlSuxA6noGpIzzBBFqacO6JEoJftIcYVM91ulehrZk3-1X75Q',
};
