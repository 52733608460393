import {Component, Input, OnInit} from '@angular/core';
import {ConfigFormBaseComponent} from '../config-form-base.component';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss']
})
export class MediaComponent extends ConfigFormBaseComponent {
}
