import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject} from "rxjs";
import NavigationEntryGroup from "../../model/navigationEntryGroup.model";
import {environment} from "../../environments/environment";
import NavigationEntry from "../../model/navigationEntry.model";
import ContentStructure from "../../vendor/ng-lion-cms/api/model/contentStructure";
import ResultList from "../../vendor/ng-lion-cms/api/model/resultlist";
import WorkspaceService from "../../vendor/ng-lion-cms/api/space/workspace.service";

@Injectable()
export default class NavigationService {
    private navigationEntriesSubject = new BehaviorSubject<NavigationEntryGroup[]>([]);
    public navigationEntries$ = this.navigationEntriesSubject.asObservable();

    public constructor(private http: HttpClient, private workspaceService: WorkspaceService) {
    }

    public loadNavigation() {
        this.http.get<ResultList<ContentStructure>>(environment.cmsUrl + '/api/content-structures?showInMenu=1').subscribe(result => {
           const regularEntries = [] as NavigationEntry[];
           const singletonEntries = [] as NavigationEntry[];
           for(let cs of result.items) {
               if(cs.singleton) {
                   singletonEntries.push({
                       label: cs.name,
                       href: '/m/' + cs.apiName + '/singleton',
                       icon: cs.menuIcon,
                       description: cs.description
                   });
               } else {
                   regularEntries.push({
                       label: cs.name,
                       href: '/m/' + cs.apiName,
                       icon: cs.menuIcon,
                       description: cs.description
                   });
               }
           }

           if(this.workspaceService.getSelectedSpaceSnapshot()?.pagebuilder) {
               regularEntries.push({
                   label: 'Seiten',
                   href: '/pages',
                   icon: 'gg:website',
                   description: 'Bearbeiten von Seiten über einen visuellen Editor'
               });
           }

           if(this.workspaceService.getSelectedSpaceSnapshot() != null) {
               regularEntries.push({
                   label: 'Mediathek',
                   href: '/medialib',
                   icon: 'iconoir:media-image-folder',
                   description: 'Eine Liste aller Medien, egal ob Bilder, Videos oder Dokumente'
               });
           }

          let navigationEntryGroups = [] as NavigationEntryGroup[];
           navigationEntryGroups.push({ label: 'Inhalt', entries: regularEntries });
           if(singletonEntries.length > 0) {
               navigationEntryGroups.push({ label: 'Einstellungen', entries: singletonEntries });
           }
           this.navigationEntriesSubject.next(navigationEntryGroups);
        });
    }
}
