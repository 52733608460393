import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MediaComponent } from './media/media.component';
import { MedialistComponent } from './medialist/medialist.component';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import { TextComponent } from './text/text.component';
import { RichtextComponent } from './richtext/richtext.component';
import { TextareaComponent } from './textarea/textarea.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { ReferenceComponent } from './reference/reference.component';
import { ReferencelistComponent } from './referencelist/referencelist.component';
import {FormsModule} from '@angular/forms';
import { DateComponent } from './date/date.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';



@NgModule({
  declarations: [MediaComponent, MedialistComponent, TextComponent, RichtextComponent, TextareaComponent, DropdownComponent, CheckboxComponent, ReferenceComponent, ReferencelistComponent, DateComponent],
    imports: [
        CommonModule,
        FormsModule,
        MatSelectModule,
        MatInputModule,
        MatSlideToggleModule
    ]
})
export class ConfigFormComponentsModule { }
