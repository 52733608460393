import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import ResultList from '../model/resultlist';
import User from '../model/user';
import Helper from '../../util/helper';
import {environment} from '../../../../environments/environment';
import Role from '../model/role';

@Injectable()
export default class RoleService {
    constructor(private http: HttpClient)
    {
    }

    list(filter?: string, sortBy?: string, sortDir?: 'asc'|'desc', page = 1, limit = 10): Observable<ResultList<Role>> {
        let query = Helper.serialize({page, limit});
        return this.http.get<ResultList<Role>>(environment.cmsUrl + '/api/roles' + '?' + query);
    }

    show(id: string): Observable<Role> {
        return this.http.get<Role>(environment.cmsUrl + '/api/roles/' + id);
    }

    update(role: Role): Observable<Role> {
        return this.http.put<Role>(environment.cmsUrl + '/api/roles/' + role.id, role);
    }

    create(role: Role): Observable<Role> {
        return this.http.post<Role>(environment.cmsUrl + '/api/roles', role);
    }

    delete(role: Role) {

    }
}
