import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import ContentStructure from '../../api/model/contentStructure';
import ContentStructureField from '../../api/model/contentStructureField';
import {ContentModelService} from '../../api/contentmodel/contentmodel.service';
import {ContentService} from '../../api/content/content.service';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {
  @Input() public apiName: string;
  @Input() public referenceId: string;
  public contentModel: ContentStructure;

  public referencedContentModel: ContentStructure;
  public selectedContentModel: ContentStructure;
  public initialized: boolean;
  @Input() public content: any;
  private instantiating: boolean;
  public inheritorModel: ContentStructure = null;


  public groupedModel: Array<{
    tab: string,
    rows: Array<Array<ContentStructureField>>
  }>;

  referenceList = {};

  constructor(private contentModelService: ContentModelService, private contentService: ContentService,
              private dialog: MatDialog) {
    this.initialized = false;
  }

  public getConfig(field: ContentStructureField, key: string, defaultValue?: any) {
    if(this.content) {
      for(const config of field.config) {
        if(config.key == key) {
          return config.value;
        }
      }
    }

    return defaultValue;
  }

  ngOnInit(): void {
    this.initialized = false;
    this.instantiating = true;
    if (this.content === '') { this.content = {}; }
    if (this.referenceId) {
      this.contentModelService.show(this.referenceId).subscribe(result => {
        const fields = JSON.parse(JSON.stringify(result.fields));
        if (this.content.inheritorModel != null) {
          if (typeof this.content.inheritorModel.fields == 'undefined') {
            this.content.inheritorModel = this.contentModelService.contentModelByApiName(this.content.inheritorModel.apiName);
          }
          for (const f of this.content.inheritorModel.fields) {
            fields.push(f);
          }
        }

        for (const field of fields) {
          if (typeof this.content[field.apiName] == 'undefined') {
            if (field.type === 'reference' || field.type === 'media') {
              this.content[field.apiName] = {};
            } else if (field.type === 'medialist') {
              this.content[field.apiName] = [];
            } else {
              this.content[field.apiName] = '';
            }
          } else if (field.type === 'reference' && this.getConfig(field, 'referenceMode') === 'select' &&
              typeof this.content[field.apiName] != 'undefined' && this.content[field.apiName] != null) {
            this.content[field.apiName] = this.content[field.apiName].id;
          }


          if (field.type === 'media' || field.type === 'medialist') {
            const maxFiles = field.type === 'media' ? 1 : this.getConfig(field, 'maxFiles', 1);
            const filetypes = [];
            const options = {} as any;
            for (const type of this.getConfig(field, 'fileTypes', [])) {
              if (type === 'jpg') { filetypes.push('image/jpeg'); }
              if (type === 'png') { filetypes.push('image/png'); }
              if (type === 'svg') { filetypes.push('image/svg'); }
              if (type === 'mp4') { filetypes.push('video/mp4'); }
              if (type === 'webm') { filetypes.push('video/webm'); }
              if (type === 'pdf') { filetypes.push('application/pdf'); }
            }
            Object.assign(options, {
              acceptedFileTypes: filetypes.join(', '),
            });


            if (field.type === 'medialist') {
              options.multiple = true;
            }

            if (typeof this.content[field.apiName] == 'undefined') {
              if (field.type === 'media') {
                this.content[field.apiName] = {
                  file: null,
                  path: null,
                  type: null,
                  name: null
                };
              } else {
                this.content[field.apiName] = [];
              }

            }
          } else if (field.type === 'reference' && this.getConfig(field, 'referenceMode') === 'select') {
            this.contentService.list(this.contentModelService.contentModelById(
                this.getConfig(field, 'referenceId')
            ).apiName, '', 'id', 'asc', 1, 99).subscribe(r => {
              this.referenceList[field.apiName] = r.items;
            });
          }
        }

        this.contentModel = result;
        if (this.content.inheritorModel != null) {
          for (const model of this.contentModel.inheritors) {
            if (model.id === this.content.inheritorModel.id) {
              this.content.inheritorModel = model;
            }
          }
        }
        this.groupedModel = [];
        for (const tab of this.tabList()) {
          console.log('tab', tab);
          this.groupedModel.push({
            tab,
            rows: this.fieldsForTabGroupedRow(tab)
          });
        }
        this.initialized = true;
        window.setTimeout(() => { this.instantiating = false; }, 3000);
      });
    } else {
      this.contentModelService.list('', 'id', 'asc').subscribe(result => {
        for (const cm of result.items) {
          if (cm.apiName === this.apiName) {
            for (const field of cm.fields) {
              if (typeof this.content[field.apiName] == 'undefined') {
                if (field.type === 'media') {
                  this.content[field.apiName] = {};
                } else if (field.type === 'medialist' || field.type === 'referencelist') {
                  this.content[field.apiName] = [];
                } else {
                  this.content[field.apiName] = '';
                }
              }
            }

            this.contentModel = cm;

            this.groupedModel = [];
            for (const tab of this.tabList()) {
              this.groupedModel.push({
                tab,
                rows: this.fieldsForTabGroupedRow(tab)
              });
            }
            break;
          }
        }
        this.initialized = true;
        window.setTimeout(() => { this.instantiating = false; }, 3000);
      });
    }
  }

  public mediumUpdated(fieldApiName, event) {
      Object.assign(this.content[fieldApiName], event);
  }

  public tabList() {
    const tabs = [];
    for (const field of this.contentModel.fields) {
      if (tabs.indexOf(field.tab) < 0) {
        tabs.push(field.tab);
      }
    }
    return tabs;
  }

  public fieldsForTabGroupedRow(tab: string): Array<Array<ContentStructureField>> {
    let filtered = this.contentModel.fields.filter(r => r.tab === tab);
    if (filtered.length === 0) { return []; }
    /* sort by row (y) */
    filtered = filtered.sort((a, b) => a.y > b.y ? 1 : -1);

    /* group fields by row */
    const rows = [];
    let currentRowList = [];
    let currentRow = filtered[0].y != null ? filtered[0].y : 1;
    for (const f of filtered) {
      if (f.cols == null) { f.cols = 12; }
      if (f.y !== currentRow) {
        if (currentRowList.length > 0) {
          rows.push(currentRowList);
        }
        currentRowList = [];
        currentRowList.push(f);
        currentRow = (f.y != null) ? f.y : currentRow;
      } else {
        currentRowList.push(f);
      }
    }
    /* only add rows that have at least one entry */
    if (currentRowList.length > 0) { rows.push(currentRowList); }
    /* order by x offset in each row */
    for (let i = 0; i < rows.length; i++) {
      rows[i] = rows[i].sort((a, b) => a.x > b.x ? 1 : -1);
    }
    return rows;
  }

  addReference(field: ContentStructureField) {
    if (this.content[field.apiName] === '') {
      this.content[field.apiName] = [];
    }

    this.content[field.apiName].push({
      inheritorModel: this.selectedContentModel
    });
  }

  getContentModel(reference) {
    return this.contentModelService.contentModelById(reference);
  }



  getWidthClassnames(cols) {
    const classnames = [];
    classnames.push('p-2');

    if (cols < 4) {
      classnames.push('w-1/2 md:w-' + cols + '/12');
    } else if (cols < 12) {
      classnames.push('w-full md:w-' + cols + '/12');
    } else {
      classnames.push('w-full');
    }

    return classnames.join(' ');
  }
}
