import {HttpClient} from "@angular/common/http";
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {concat, forkJoin, Observable, of, throwError} from "rxjs";
import {catchError, finalize, map, switchMap} from "rxjs/operators";
import {Injectable} from "@angular/core";
import AuthService from '../api/auth.service';

@Injectable({
  providedIn: 'root'
})
export class InitialDataResolver implements Resolve<any> {
  constructor(
    private _httpClient: HttpClient,
    private router: Router,
    private authService: AuthService
  ) {
  }

  private _loadUser() {
    return this.authService.loadUser();
  }

  /**
   * Resolver
   *
   * @param route
   * @param state
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>
  {
    return forkJoin([
      this._loadUser()
    ])
      .pipe(
        catchError(error => {
            return of(null);
        }),
        map(data => {
          if(data == null) {
            this.router.navigate(['/auth/login']);
          }
          return data;
        })
    );
  }
}
