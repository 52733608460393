import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import RequestInterceptor from '../interceptor/request.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { BaseComponent } from './base/base.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatInputModule} from '@angular/material/input';
import {SharedModule} from './shared/shared.module';
import {NgxMatDateAdapter} from '@angular-material-components/datetime-picker';
import {NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, NgxMatMomentAdapter, NgxMatMomentModule} from '@angular-material-components/moment-adapter';
import {MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {DialogsModule} from './dialogs/dialogs.module';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS} from '@angular/material/snack-bar';
import GlobalStorageService from './helpers/globalStorage.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {Nl2BrPipe} from './pipes/nl2br.pipe';
import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import {NgLionCmsModule} from '../vendor/ng-lion-cms/ng-lion-cms.module';
import {GridsterModule} from 'angular-gridster2';
import { LoginFormComponent } from './login-form/login-form.component';
import NavigationService from "./services/navigation.service";
import {DeviceDetectorModule} from "ngx-device-detector";
import {QuillModule} from "ngx-quill";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';

export const MY_DATE_FORMAT = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD.MM.YYYY HH:mm',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

registerLocaleData(localeDe, 'de', localeDeExtra);


@NgModule({
    declarations: [
        AppComponent,
        BaseComponent
    ],
    imports: [
        MatSidenavModule,
        DeviceDetectorModule.forRoot(),
        SharedModule,
        AppRoutingModule,
        ToastrModule.forRoot(),
        MatInputModule,
        DialogsModule,
        NgxMatMomentModule,
        NgLionCmsModule,
        GridsterModule,
        ServiceWorkerModule.register('custom-service-worker.js', {
            enabled: environment.production,
            registrationStrategy: 'registerImmediately'
        })
    ],
    providers: [
        Nl2BrPipe,
        NavigationService,
        GlobalStorageService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestInterceptor, // this class adds channel to header
            multi: true
        },
        {
            provide: NgxMatDateAdapter,
            useClass: NgxMatMomentAdapter,
            deps: [MAT_DATE_LOCALE, NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        {provide: LOCALE_ID, useValue: 'de'},
        {provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMAT},
        {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 3000}},
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } }
    ],
    exports: [
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
