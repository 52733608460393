<div mat-dialog-title>Mediathek</div>
<div mat-dialog-content>
    <div class="dialog-closer" mat-dialog-close></div>
    <div class="flex flex-wrap -mx-1">
        <div class="w-2/3 px-1">
            <input type="text" ngModel (ngModelChange)="changeQuery($event)" class="styled" placeholder="Suche" />
            <mat-progress-bar *ngIf="(loading$ | async)" [mode]="'indeterminate'"></mat-progress-bar>
            <div class="flex flex-wrap w-full -mx-2">
                <div class="w-1/3 lg:w-1/4 p-2" *ngFor="let medium of (media$ | async)" (click)="selectMedium(medium)" [class.medium-selected]="isSelected(medium)">
                    <div class="media aspect-1-1 bg-gray-200 rounded-lg shadow-lg cursor-pointer medium-preview">
                        <div class="overlay full bg-cover bg-center" *ngIf="medium.type.indexOf('image') >= 0" [style.background-image]="'url(' + medium.file + ')'"></div>
                        <div class="overlay full bg-cover bg-center" *ngIf="medium.type.indexOf('video') >= 0">
                            <video class="w-full h-full object-cover object-center" muted playsinline>
                                <source [src]="medium.file" />
                            </video>
                        </div>

                        <div class="overlay top-0 left-0 pt-1 pl-1">
                            <div class="medium-checkbox"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!(loading$ | async) && total == 0" class="text-center">
                <div class="text-6xl mb-2 mt-6">
                    <span class="iconify inline" data-icon="noto-v1:man-shrugging-medium-skin-tone"></span>
                </div>
                <div class="text-xl text-gray-500">
                    Kein Medium gefunden
                </div>
            </div>

        </div>
        <div class="w-1/3 pl-4 pr-1">
            <div class="flex flex-wrap -mx-2" *ngIf="previewedMedium">
                <div class="w-full px-2 border-b border-gray-200 py-1">
                    <div class="font-bold text-sm">Dateiname</div>
                    {{ previewedMedium.filename }}
                </div>
                <div class="w-full px-2 border-b border-gray-200 py-1">
                    <div class="font-bold text-sm">Erstellt am</div>
                    {{ previewedMedium.created | date: 'dd.MM.yyyy HH:mm' }}
                </div>
                <div class="w-full px-2 border-b border-gray-200 py-1">
                    <div class="font-bold text-sm">Erstellt von</div>
                    {{ previewedMedium.author.displayName }}
                </div>
                <div class="w-full px-2 border-b border-gray-200 py-1">
                    <div class="font-bold text-sm">Zuletzt aktualisiert am</div>
                    {{ previewedMedium.updated | date: 'dd.MM.yyyy HH:mm' }}
                </div>
                <div class="w-full px-2 border-b border-gray-200 py-1">
                    <div class="font-bold text-sm">Beschreibung</div>
                    <textarea class="styled" [(ngModel)]="previewedMedium.description"></textarea>
                </div>
                <div class="w-full px-2 border-b border-gray-200 py-1">
                    <div class="font-bold text-sm">Stichworte</div>
                    <input type="text" [(ngModel)]="previewedMedium.keywords" class="styled"/>
                </div>

                <div class="mt-4 text-xs pl-2 text-red-600 hover:underline cursor-pointer inline-block">
                    Medium löschen
                </div>
            </div>
        </div>
    </div>
    <input type="file" class="hidden" #fileInput [multiple]="true" (change)="selectedFiles(fileInput.files)" />
</div>
<div mat-dialog-actions>
    <div class="px-4 text-right w-full">
        <div class="btn sm mr-3" mat-dialog-close>Abbrechen</div>
        <div class="btn sm secondary mr-3" (click)="fileInput.click()">Medium hochladen</div>
        <div class="btn sm primary" (click)="save()">Übernehmen</div>
    </div>

</div>
