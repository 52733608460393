import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxMatDatetimePickerModule} from '@angular-material-components/datetime-picker';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatRippleModule} from '@angular/material/core';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import { EditSpaceComponent } from './edit-space/edit-space.component';
import { AddPageComponent } from './add-page/add-page.component';
import { InvitationDialogComponent } from './invitation-dialog/invitation-dialog.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSelectModule} from '@angular/material/select';
import { TranslationDialogComponent } from './translation-dialog/translation-dialog.component';
import { MedialibSettingsDialogComponent } from './medialib-settings-dialog/medialib-settings-dialog.component';


@NgModule({
  declarations: [ConfirmDialogComponent, EditSpaceComponent, AddPageComponent, InvitationDialogComponent, TranslationDialogComponent, MedialibSettingsDialogComponent],
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        FormsModule,
        MatIconModule,
        NgxMatDatetimePickerModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatMomentDateModule,
        MatRippleModule,
        MatCheckboxModule,
        MatSelectModule,
    ]
})
export class DialogsModule { }
