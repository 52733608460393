<div *ngIf="getFieldConfigValue('referenceMode') == 'select'">
    <mat-label>{{field.name}}</mat-label>
    <mat-form-field class="w-full styled">
        <mat-select [(ngModel)]="content[field.apiName]" [placeholder]="field.name">
            <mat-option *ngFor="let ref of referenceList[field.apiName]"
                        [value]="ref.id">
                {{ ref[getFieldConfigValue('listLabel')] }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div *ngIf="getFieldConfigValue('referenceMode') != 'select'">
    <h2 class="mt-0">{{ field.name }}</h2>
    <app-content
            [content]="content[field.apiName]"
            [referenceId]="getFieldConfigValue('referenceId')"></app-content>
</div>
