import { Component, OnInit } from '@angular/core';
import {ConfigFormBaseComponent} from '../config-form-base.component';
import {BehaviorSubject} from 'rxjs';
import ContentStructure from '../../api/model/contentStructure';
import {ContentModelService} from '../../api/contentmodel/contentmodel.service';

@Component({
  selector: 'app-reference',
  templateUrl: './reference.component.html',
  styleUrls: ['./reference.component.scss']
})
export class ReferenceComponent extends ConfigFormBaseComponent {
  private contentModelsSubject = new BehaviorSubject<ContentStructure[]>([]);
  public contentModels$ = this.contentModelsSubject.asObservable();

  public constructor(private contentModelService: ContentModelService) {
    super();
    this.contentModelService.list(null).subscribe(result => {
      this.contentModelsSubject.next(result.items);
    });
  }

  public referenceCompareFn(a: any, b: any): boolean {
    return a == b;
  }
}
