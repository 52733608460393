import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import MediumModel from '../../../../model/crm/medium.model';
import MediaService from '../../api/media/media.service';
import {BehaviorSubject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-media-dialog',
  templateUrl: './media-dialog.component.html',
  styleUrls: ['./media-dialog.component.scss']
})
export class MediaDialogComponent implements OnInit {
  @ViewChild("fileInput") fileInput;

  private mediaSubject = new BehaviorSubject<MediumModel[]>([]);
  public media$ = this.mediaSubject.asObservable();

  private querySubject = new BehaviorSubject<string>('');
  public query$ = this.querySubject.asObservable();

  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  public total = 0;
  public page = 1;

  private uploadingSubject = new BehaviorSubject<boolean>(false);
  public uploading$ = this.uploadingSubject.asObservable();

  public selected: MediumModel[];

  public previewedMedium: MediumModel;

  constructor(public dialogRef: MatDialogRef<MediaDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: MediaDialogModel,
              private mediaService: MediaService) { }

  ngOnInit(): void {
    console.log('init fired');
    this.query$.pipe(distinctUntilChanged(), debounceTime(300)).subscribe(query => {
      this.loadMedia();
    });
    if(typeof this.data.selectedMedia.length != 'undefined') {
      this.selected = JSON.parse(JSON.stringify(this.data.selectedMedia));
    } else {
      this.selected = [];
    }

  }

  loadMedia() {
    this.page = 1;
    this.loadingSubject.next(true);
    this.mediaService.list(this.querySubject.getValue(), 1, 16).subscribe(result => {
      this.mediaSubject.next(result.results);
      this.loadingSubject.next(false);
      this.total = result.total;
    }, error => {
      this.loadingSubject.next(false);
    });
  }

  loadMore() {
    this.page++;
    this.loadingSubject.next(true);
    this.mediaService.list(this.querySubject.getValue(), this.page, 16).subscribe(result => {
      let media = this.mediaSubject.getValue();
      media = media.concat(result.results);
      this.mediaSubject.next(media);
      this.loadingSubject.next(false);
    }, error => {
      this.loadingSubject.next(false);
    });
  }

  changeQuery(query: string) {
    this.querySubject.next(query);
  }

  selectedFiles(files) {
    if (files.length === 0)
      return;

    let totalFiles = files.length;
    let loaded = 0;

    this.uploadingSubject.next(true);

    for(let file of files) {
      var mimeType = file.type;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event) => {
        let medium = {
          payload: reader.result.toString(),
          name: this.fileInput.nativeElement.value.split(/(\\|\/)/g).pop()
        } as MediumModel;
        this.mediaService.create(medium).subscribe(result => {
          loaded++;
          if(loaded == totalFiles) {
            this.uploadingSubject.next(false);
          }
          let media = this.mediaSubject.getValue();
          media.unshift(result);
          this.mediaSubject.next(media);
        }, error => {
          loaded++;
          if(loaded == totalFiles) {
            this.uploadingSubject.next(false);
          }
        });

      };
    }
  }

  getSelectedMediumIndex(medium: MediumModel) {
    for(let i = 0; i < this.selected.length; i++) {
      if(this.selected[i] && this.selected[i].id == medium.id) {
        return i;
      }
    }
    return -1;
  }

  isSelected(medium: MediumModel) {
    return this.getSelectedMediumIndex(medium) >= 0;
  }

  selectMedium(medium: MediumModel) {
    this.previewedMedium = medium;
    const index = this.getSelectedMediumIndex(medium);
    if(this.data.multiple) {
      if(index >= 0) {
        this.selected.splice(index, 1);
      } else {
        this.selected.push(medium);
      }
    } else {
      this.selected.length = 0;
      this.selected.push(medium);
    }
  }

  save() {
    console.log(this.dialogRef);
    console.log('SAVING');
    if(this.data.multiple) {
      this.dialogRef.close(this.selected);
    } else if(this.selected.length == 1) {
      this.dialogRef.close(this.selected[0]);
    } else {
      this.dialogRef.close();
    }
  }
}

export class MediaDialogModel {
  public constructor(public selectedMedia: MediumModel[], public multiple: boolean, public allowedTypes?: string[]) {
  }
}
