import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ContentModelService} from '../contentmodel/contentmodel.service';
import {environment} from 'src/environments/environment';
import {BehaviorSubject, Observable, of} from 'rxjs';
import ResultList from '../model/resultlist';
import ContentStructure from '../model/contentStructure';
import Helper from '../../util/helper';
import Workspace from '../model/workspace';
import {catchError, switchMap} from 'rxjs/operators';
import Role from '../model/role';
import Invitation from '../model/invitation';

@Injectable()
export default class WorkspaceService {
  private endpoint: string;

  private selectedSpaceSubject = new BehaviorSubject<Workspace>(null);
  public selectedSpace$ = this.selectedSpaceSubject.asObservable();

  constructor(private http: HttpClient)
  {
    this.endpoint = environment.cmsUrl;
    if(localStorage.getItem('workspace')) {
      this.list().subscribe(result => {
          for(let ws of result.items) {
            if(ws.id == localStorage.getItem('workspace')) {
              this.selectedSpaceSubject.next(ws);
              return;
            }
          }
          if(result.items.length > 0) {
            this.selectedSpaceSubject.next(result.items[0]);
          }
      });
    }
  }

  list(filter?: string, sortBy?: string, sortDir?: 'asc'|'desc', page = 1, limit = 10): Observable<ResultList<Workspace>> {
    let query = Helper.serialize({page, limit});
    return this.http.get<ResultList<Workspace>>(this.endpoint + '/api/workspaces' + '?' + query + '&XDEBUG_SESSION_START=phpstorm');
  }

  create(space: Workspace): Observable<Workspace> {
    return this.http.post<Workspace>(this.endpoint + '/api/workspaces', space);
  }

  update(space: Workspace): Observable<Workspace> {
    return this.http.put<Workspace>(this.endpoint + '/api/workspaces/' + space.id, space);
  }

  show(id: string): Observable<Workspace> {
    return this.http.get<Workspace>(this.endpoint + '/api/workspaces/' + id);
  }

  delete(space: Workspace) {
    return this.http.delete<boolean>(this.endpoint + '/api/workspaces/' + space.id)
      .pipe(
        switchMap(e => of(true)),
        catchError(e => of(false))
      );
  }

  public setSelectedSpace(space: Workspace) {
    this.selectedSpaceSubject.next(space);
  }

  public getSelectedSpaceSnapshot() {
    return this.selectedSpaceSubject.getValue();
  }

  public invite(role: Role, email: string, url?: string): Observable<Invitation> {
    return this.http.post<Invitation>(this.endpoint + '/api/workspaces/invite', {
      role_id: role.id,
      email: email,
      url: url
    });
  }

  public getInvitation(token: string): Observable<Invitation> {
    return this.http.get<Invitation>(this.endpoint + '/api/workspaces/invitation/' + token);
  }

  public acceptInvitation(token: string): Observable<boolean> {
    return this.http.post<any>(this.endpoint + '/api/workspaces/accept-invitation/' + token, {}).pipe(
        switchMap(() => of(true)),
        catchError(e => of(false))
    );
  }
}
