import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ContentComponent} from './components/content/content.component';
import {ContentModelService} from './api/contentmodel/contentmodel.service';
import {ContentService} from './api/content/content.service';
import {ModelHookService} from './api/modelhook/modelhook.service';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatRippleModule} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSelectModule} from '@angular/material/select';
import {IconSelectorComponent} from './components/icon-selector/icon-selector.component';
import {MatTabsModule} from '@angular/material/tabs';
import {MatMenuModule} from '@angular/material/menu';
import WorkspaceService from './api/space/workspace.service';
import { ContentModelFieldDialogComponent } from './components/content-model-field-dialog/content-model-field-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import {QuillModule} from 'ngx-quill';
import PageService from './api/page/page.service';
import { MediaDialogComponent } from './components/media-dialog/media-dialog.component';
import MediaService from './api/media/media.service';
import {ConfigFormComponentsModule} from './config-form-components/config-form-components.module';
import {FormComponentsModule} from './form-components/form-components.module';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {ContentModule} from './components/content/content.module';
import {ServicesModule} from './service/services.module';
import UserService from './api/user/user.service';
import RoleService from './api/role/role.service';
import ApiAccessService from './api/api-access/api-access.service';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import LanguageService from './service/language.service';
import TranslationService from './api/translation/translation.service';
import {ComponentsModule} from './components/components.module';

@NgModule({
  declarations: [
  ],
    imports: [
        CommonModule,
        MatFormFieldModule,
        FormsModule,
        ReactiveFormsModule,
        MatRippleModule,
        MatIconModule,
        MatButtonModule,
        MatCheckboxModule,
        MatSelectModule,
        MatTabsModule,
        MatMenuModule,
        MatDialogModule,
        MatInputModule,
        QuillModule,
        ConfigFormComponentsModule,
        FormComponentsModule,
        MatProgressBarModule,
        ContentModule,
        ServicesModule,
        MatSlideToggleModule,
        ComponentsModule
    ],
  providers: [
    ContentModelService,
    ContentService,
    WorkspaceService,
    ModelHookService,
    PageService,
      MediaService,
      UserService,
      RoleService,
      ApiAccessService,
      LanguageService,
      TranslationService
  ]
})
export class NgLionCmsModule { }
