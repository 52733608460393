import { Component, OnInit } from '@angular/core';
import {FormBaseComponent} from '../form-base.component';
import {ContentService} from '../../api/content/content.service';
import {ContentModelService} from '../../api/contentmodel/contentmodel.service';

@Component({
  selector: 'app-reference',
  templateUrl: './reference.component.html',
  styleUrls: ['./reference.component.scss']
})
export class ReferenceComponent extends FormBaseComponent implements OnInit {
  referenceList = {};

  public constructor(private contentService: ContentService, private contentModelService: ContentModelService) {
    super();

  }

  ngOnInit(): void {
    this.contentService.list(this.contentModelService.contentModelById(
        this.getFieldConfigValue( 'referenceId')
    ).apiName, '', 'id', 'asc', 1, 99).subscribe(r => {
      this.referenceList[this.field.apiName] = r.items;
    });
  }
}
