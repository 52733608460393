import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable, Injector, isDevMode} from "@angular/core";
import {Observable, of, throwError} from "rxjs";
import {environment} from "../environments/environment";
import {ToastrService} from "ngx-toastr";
import {catchError} from "rxjs/operators";
import AuthService from '../api/auth.service';
import WorkspaceService from '../vendor/ng-lion-cms/api/space/workspace.service';
import LanguageService from '../vendor/ng-lion-cms/service/language.service';

@Injectable()
export default class RequestInterceptor implements HttpInterceptor {
    private authService: AuthService;
    private languageService: LanguageService;
    private spaceService: WorkspaceService;

    private authRequiredUrls = ['/'];

    constructor(inj: Injector) {
        this.authService = inj.get(AuthService);
        this.spaceService = inj.get(WorkspaceService);
        this.languageService = inj.get(LanguageService);
    }


    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let headers = {
            'Content-Type': 'application/json'
        };

        if(req.headers.has('wo-intercept')) {
          let headers = req.headers;
          headers = headers.delete('wo-intercept');
          req = req.clone({
            headers: headers
          });
          return next.handle(req);
        }

        if(this.authService.getConnectionToken()) {
            if(environment.loginMode == 'sso') {
                headers['X-Sso-Token'] = this.authService.getConnectionToken();
            } else {
                headers['Authorization'] = 'Bearer ' + this.authService.getConnectionToken();
            }
        }

        if(this.spaceService.getSelectedSpaceSnapshot() != null && this.spaceService.getSelectedSpaceSnapshot().id) {
          headers['X-Workspace'] = this.spaceService.getSelectedSpaceSnapshot().id;
        } else if(localStorage.getItem('workspace')) {
            headers['X-Workspace'] = localStorage.getItem('workspace');
        }

        headers['X-Locale'] = this.languageService.getActiveLanguageSnapshot();


        let newReq = req.clone({
            headers: new HttpHeaders(headers)
        });

        /* Add xdebug param */
        if(isDevMode() && environment.xdebug) {
            let newUrl = {urlWithParams: newReq.urlWithParams + '?XDEBUG_SESSION_START=phpstorm'};
            if (newReq.urlWithParams.indexOf('?') > 0) {
                newUrl = {urlWithParams: newReq.urlWithParams + '&XDEBUG_SESSION_START=phpstorm'};
            }
            newReq = Object.assign(newReq, newUrl);
        }

        return next.handle(newReq).pipe(catchError(err => {
            if(err.status == 401 && this.authService.token != null) {
                /* redo the request without auth */
                // todo: redirect to login instead
                let headers = {
                    'Content-Type': 'application/json'
                };
                newReq = newReq.clone({
                    headers: new HttpHeaders(headers)
                });
                return next.handle(newReq);
            } else if(err.status == 401) {
                /* redo the request without auth */
                // todo: redirect to login instead
                let headers = {
                  'Content-Type': 'application/json'
                };
                newReq = newReq.clone({
                  headers: new HttpHeaders(headers)
                });

                return next.handle(newReq);
            }
            return throwError(err);
        }));
    }
}
