<div class="w-full">
    <mat-label>{{ field.name }}</mat-label>
    <mat-form-field (click)="showMedialib()">
        <mat-select [placeholder]="content[field.apiName]?.originalFilename ? content[field.apiName].originalFilename : 'Datei auswählen...'" (click)="$event.preventDefault()"></mat-select>
        <div class="media-preview">
            <div class="media aspect-1-1 bg-gray-200 cursor-pointer hover:bg-gray-300 rounded-lg">
                <div class="overlay full" *ngIf="content[field.apiName] && content[field.apiName].file">
                    <img *ngIf="isImage()" [src]="content[field.apiName].file" alt="" />
                    <video class="w-full h-full object-cover object-center" *ngIf="isVideo()" muted playsinline>
                        <source [src]="content[field.apiName].file"/>
                    </video>
                </div>
                <div class="overlay left-0 right-0 vertical-center text-center text-gray-600 text-sm" *ngIf="!content[field.apiName] || !content[field.apiName].file">
                    <div class="text-lg">
                        <span class="iconify inline" data-icon="clarity:image-line"></span>
                    </div>
                </div>
            </div>
        </div>
    </mat-form-field>


</div>
