import { Component, OnInit } from '@angular/core';
import {FormBaseComponent} from '../form-base.component';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss']
})
export class DateComponent extends FormBaseComponent {
  public minDate: Date;
  public maxDate: Date;

  public constructor() {
    super();
    this.minDate = new Date();
    this.maxDate = (new Date());
    this.maxDate.setFullYear(2040,1,1);
  }

  public setDate(date) {
    this.content[this.field.apiName] = date.format();
  }
}
