<div>
    <div *ngIf="initialized">
        <div *ngIf="groupedModel && groupedModel.length == 1">
            <div class="flex flex-wrap w-full -m-2" *ngFor="let row of groupedModel[0].rows">

                <div [className]="getWidthClassnames(field.cols)"
                     *ngFor="let field of row">

                    <div fieldType [contentStructureField]="field" [ctx]="content"></div>
                </div>

            </div>
        </div>

        <div *ngIf="groupedModel && groupedModel.length > 1">
            <mat-tab-group>
                <mat-tab *ngFor="let tab of groupedModel" [label]="tab.tab">
                    <div class="bg-white p-4">
                        <div class="flex flex-wrap -m-2 w-full" *ngFor="let row of tab.rows">
                            <div [className]="getWidthClassnames(field.cols)"
                                 *ngFor="let field of row">
                                <div fieldType [contentStructureField]="field" [ctx]="content"></div>
                            </div>
                        </div>

                    </div>
                </mat-tab>
            </mat-tab-group>

        </div>

        <div *ngIf="content.inheritorModel != null && contentModel.abstract">
            <app-content
                    [content]="content"
                    [referenceId]="content.inheritorModel.id"></app-content>
        </div>
    </div>
</div>
