import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {FormBaseComponent} from '../form-base.component';
import {MediaDialogComponent, MediaDialogModel} from '../../components/media-dialog/media-dialog.component';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss']
})
export class MediaComponent extends FormBaseComponent {
  constructor(private dialog: MatDialog) {
    super();
  }

  isImage() {
    return this.content[this.field.apiName].type.indexOf('image') >= 0;
  }

  isVideo() {
    return this.content[this.field.apiName].type.indexOf('video') >= 0;
  }

  showMedialib() {
    let data = new MediaDialogModel([this.content[this.field.apiName]], false, this.getFieldConfigValue('fileTypes'));

    let dialogRef = this.dialog.open(MediaDialogComponent, {
      data: data,
      panelClass: 'medialib-dialog'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.content[this.field.apiName] = result;
      }
    });
  }


}
