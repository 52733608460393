import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {environment} from '../environments/environment';
import {ActivatedRoute, ActivatedRouteSnapshot, ActivationEnd, NavigationEnd, Router, RouterEvent} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import GlobalStorageService from './helpers/globalStorage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  data = null;

  @ViewChild('splashScreen') splashScreen: ElementRef;

  public constructor(private titleService: Title,
                     private router: Router,
                     private route: ActivatedRoute,
                     private toastr: ToastrService,
                     private store: GlobalStorageService) {
    this.titleService.setTitle('CMS');
  }

  ngOnInit() {
    this.route.data.subscribe((data) => {
    });

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  removeSplashScreen() {
  }

  ngAfterViewInit() {

  }


  closeNav() {

  }

  openNav() {

  }
}
