<div class="flex flex-wrap">
    <div class="w-full px-2 lg:w-1/3">
        <label>Zieltyp</label>
        <mat-select [compareWith]="referenceCompareFn" [value]="getConfigFieldValue('referenceId')" data-placeholder="Zieltyp" class="styled" (selectionChange)="updateConfigField('referenceId', $event.value)">
            <mat-option *ngFor="let cm of (contentModels$ | async)" [value]="cm.id">{{ cm.name }}</mat-option>
        </mat-select>
    </div>
    <div class="w-full px-2 lg:w-1/3">
        <label>Anzeige</label>
        <mat-select class="styled" [value]="getConfigFieldValue('mode')" (selectionChange)="updateConfigField('mode', $event.value)">
            <mat-option [value]="'inline'">Inline</mat-option>
            <mat-option [value]="'popup'">Popup</mat-option>
        </mat-select>
    </div>
    <div *ngIf="field.type == 'reference'" class="w-full lg:w-1/3 px-2">
        <label>Modus</label>
        <mat-select [value]="getConfigFieldValue('referenceMode')" class="styled" (selectionChange)="updateConfigField('referenceMode', $event.value)">
            <mat-option [value]="'create'">Neu erstellen</mat-option>
            <mat-option [value]="'select'">Auswahl aus Liste</mat-option>
        </mat-select>
    </div>
    <div *ngIf="field.type == 'reference'" class="w-full lg:w-1/2 px-2">
        <label>Beschriftungsfeld</label>
        <mat-form-field>
            <input type="text" matInput
                   [autocomplete]="'off'"
                   [value]="getConfigFieldValue('listLabel')"
                   (change)="updateConfigField('listLabel', $event.target.value)"
                   [placeholder]="'Beschriftungsfeld'">
        </mat-form-field>

    </div>
</div>
