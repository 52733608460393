import { Component, OnInit } from '@angular/core';
import {FormBaseComponent} from '../form-base.component';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent extends FormBaseComponent {

}
