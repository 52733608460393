import { Component, OnInit } from '@angular/core';
import {FormBaseComponent} from '../form-base.component';
import {MatDialog} from '@angular/material/dialog';
import {ContentModelService} from '../../api/contentmodel/contentmodel.service';
import ContentStructure from '../../api/model/contentStructure';
import ContentStructureField from '../../api/model/contentStructureField';

@Component({
  selector: 'app-referencelist',
  templateUrl: './referencelist.component.html',
  styleUrls: ['./referencelist.component.scss']
})
export class ReferencelistComponent extends FormBaseComponent {
  public selectedContentModel: ContentStructure;

  public constructor(private dialog: MatDialog, private contentModelService: ContentModelService) {
    super();
  }
  public selectedReference: any = null;
  openModal(templateRef, reference) {
    this.selectedReference = reference;
    const dialogRef = this.dialog.open(templateRef, {
      minWidth: '80vw',
      maxWidth: '90vw',
      width: '100%',
      height: '80vh'
    });
  }

  moveUp(fieldApiName: string, reference) {
    const field = this.content[fieldApiName];
    const offset = this.content[fieldApiName].indexOf(reference);
    if (offset > 0) {
      const memory = field[offset - 1];
      field[offset - 1] = field[offset];
      field[offset] = memory;
    }
  }

  moveDown(fieldApiName: string, reference) {
    const field = this.content[fieldApiName];
    const offset = this.content[fieldApiName].indexOf(reference);
    if (offset + 1 < field.length) {
      const memory = field[offset + 1];
      field[offset + 1] = field[offset];
      field[offset] = memory;
    }
  }

  removeReference(fieldApiName: string, reference: any) {
    this.content[fieldApiName].splice(
        this.content[fieldApiName].indexOf(reference), 1
    );
  }

  getContentModel(reference) {
    return this.contentModelService.contentModelById(reference);
  }

  addReference(field: ContentStructureField) {
    if (this.content[field.apiName] === '') {
      this.content[field.apiName] = [];
    }

    this.content[field.apiName].push({
      inheritorModel: this.selectedContentModel
    });
  }
}
