import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule} from '@angular/router';
import AuthService from './auth.service';
import {HttpClientModule} from '@angular/common/http';



@NgModule({
  providers: [
    AuthService,
  ],
  imports: [
    CommonModule,
    HttpClientModule
  ]
})
export class ApiModule { }
