import { Component, OnInit } from '@angular/core';
import {ConfigFormBaseComponent} from '../config-form-base.component';

@Component({
  selector: 'app-richtext',
  templateUrl: './richtext.component.html',
  styleUrls: ['./richtext.component.scss']
})
export class RichtextComponent extends ConfigFormBaseComponent {

}
