import {MediaComponent as MediaConfigComponent} from '../config-form-components/media/media.component';
import {MedialistComponent as MedialistConfigComponent} from '../config-form-components/medialist/medialist.component';
import {ReferencelistComponent as ReferencelistConfigComponent} from '../config-form-components/referencelist/referencelist.component';
import {TextComponent as TextConfigComponent} from '../config-form-components/text/text.component';
import {RichtextComponent as RichtextConfigComponent} from '../config-form-components/richtext/richtext.component';
import {TextareaComponent as TextareaConfigComponent} from '../config-form-components/textarea/textarea.component';
import {ReferenceComponent as ReferenceConfigComponent} from '../config-form-components/reference/reference.component';
import {DropdownComponent as DropdownConfigComponent} from '../config-form-components/dropdown/dropdown.component';
import {CheckboxComponent as CheckboxConfigComponent} from '../config-form-components/checkbox/checkbox.component';

import {TextComponent} from '../form-components/text/text.component';
import {RichtextComponent} from '../form-components/richtext/richtext.component';
import {MediaComponent} from '../form-components/media/media.component';
import {MedialistComponent} from '../form-components/medialist/medialist.component';
import {ReferenceComponent} from '../form-components/reference/reference.component';
import {ReferencelistComponent} from '../form-components/referencelist/referencelist.component';
import {TextareaComponent} from '../form-components/textarea/textarea.component';
import {DropdownComponent} from '../form-components/dropdown/dropdown.component';
import {CheckboxComponent} from '../form-components/checkbox/checkbox.component';
import {DateComponent} from '../form-components/date/date.component';
import {DateComponent as DateConfigComponent} from '../config-form-components/date/date.component';


export default class FieldTypeService {
    private fieldTypeRenderer = {} as any;
    private fieldTypeConfigRenderer = {} as any;
    private info = {} as any;

    public constructor() {
        this.registerFieldType('Medium', 'media', MediaComponent, MediaConfigComponent);
        this.registerFieldType('Medien', 'medialist', MedialistComponent, MedialistConfigComponent);
        this.registerFieldType('Referenzliste', 'referencelist', ReferencelistComponent, ReferencelistConfigComponent);
        this.registerFieldType('Referenz', 'reference', ReferenceComponent, ReferenceConfigComponent);
        this.registerFieldType('Textbox', 'text', TextComponent, TextConfigComponent);
        this.registerFieldType('Formatierter Text', 'richtext', RichtextComponent, RichtextConfigComponent);
        this.registerFieldType('Mehrzeiliger Text', 'textarea', TextareaComponent, TextareaConfigComponent);
        this.registerFieldType('Klappliste', 'dropdown', DropdownComponent, DropdownConfigComponent);
        this.registerFieldType('Checkbox', 'checkbox', CheckboxComponent, CheckboxConfigComponent);
        this.registerFieldType('Datum', 'date', DateComponent, DateConfigComponent);
    }

    public registerFieldType(name: string, fieldType: string, renderComponent: any, configRenderComponent?: any, config?: any) {
        this.fieldTypeRenderer[fieldType] = renderComponent;
        if(configRenderComponent) {
            this.fieldTypeConfigRenderer[fieldType] = configRenderComponent;
        }
        let info = { name: name, fieldType: fieldType };
        if(config) {
            Object.assign(info, config);
        }
        this.info[fieldType] = info;
    }

    public getFieldTypeInfo() {
        return this.info;
    }

    public getRenderComponent(fieldType: string) {
        if(typeof this.fieldTypeRenderer[fieldType] != 'undefined') {
            return this.fieldTypeRenderer[fieldType];
        }
        return null;
    }

    public getConfigRenderComponent(fieldType: string) {
        if(typeof this.fieldTypeConfigRenderer[fieldType] != 'undefined') {
            return this.fieldTypeConfigRenderer[fieldType];
        }
        return null;
    }
}
