import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import ResultList from "../model/resultlist";
import ContentStructure from "../model/contentStructure";
import Helper from "../../util/helper";
import ApiAccess from "../model/apiAccess";
import {environment} from "../../../../environments/environment";

@Injectable()
export default class ApiAccessService {
    constructor(private http: HttpClient)
    {

    }

    list(filter: string, sortBy?: string, sortDir?: 'asc'|'desc', page = 1, limit = 30, additionalData?: any): Observable<ResultList<ApiAccess>> {
        let queryData = {page, limit};
        if(additionalData) {
            queryData = Object.assign(queryData, additionalData);
        }
        let query = Helper.serialize(queryData);
        return this.http.get<ResultList<ApiAccess>>(environment.cmsUrl + '/api/api-access?' + query);
    }

    show(id: string): Observable<ApiAccess> {
        return this.http.get<ApiAccess>(environment.cmsUrl + '/api/api-access/' + id);
    }

    create(apiAccess: ApiAccess): Observable<ApiAccess> {
        return this.http.post<ApiAccess>(environment.cmsUrl + '/api/api-access', apiAccess);
    }

    update(apiAccess: ApiAccess): Observable<ApiAccess> {
        return this.http.put<ApiAccess>(environment.cmsUrl + '/api/api-access/' + apiAccess.id, apiAccess);
    }

    delete(apiAccess: ApiAccess): Observable<ApiAccess> {
        return this.http.delete<ApiAccess>(environment.cmsUrl + '/api/api-access/' + apiAccess.id);
    }
}
