import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {catchError, switchMap} from "rxjs/operators";
import {environment} from 'src/environments/environment';
import Helper from '../../util/helper';
import {ContentModelService} from '../contentmodel/contentmodel.service';
import ResultList from '../model/resultlist';
import ContentStructure from '../model/contentStructure';
import Content from '../model/content';

@Injectable()
export class ContentService
{
    private endpoint: string;


    constructor(private http: HttpClient, private contentModelService: ContentModelService)
    {
        this.endpoint = environment.cmsUrl;
    }

    list(apiName: string, filter: string, sortBy: string, sortDir: 'asc'|'desc', page = 1, limit = 10): Observable<ResultList<Content>> {
        let query = Helper.serialize({page, limit, filter});
        return this.http.get<ResultList<Content>>(this.endpoint + '/api/' + apiName + '?' + query + '&XDEBUG_SESSION_START=phpstorm');
    }

    show(apiName: string, id: string): Observable<Content> {
        return this.http.get<Content>(this.endpoint + '/api/' + apiName + '/' + id + '?XDEBUG_SESSION_START=phpstorm');
    }

    singleton(apiName: string): Observable<Content> {
        return this.http.get<Content>(this.endpoint + '/api/' + apiName + '/singleton');
    }

    update(apiName: string, content: Content): Observable<boolean> {
        return this.http.put<any>(this.endpoint + '/api/' + apiName + '/' + content.id + '?XDEBUG_SESSION_START=phpstorm', content)
            .pipe(switchMap(result => of(true)), catchError(err => of(false)));
    }

    create(apiName: string, content: Content): Observable<Content> {
        return this.http.post<Content>(this.endpoint + '/api/' + apiName + '?XDEBUG_SESSION_START=phpstorm', content);
    }

    public serializeContent(content: Content, model: ContentStructure) {
        let obj = Object.assign({}, content);
        let fields = JSON.parse(JSON.stringify(model.fields));
        if(content.inheritorModel != null) {
            for(let f of content.inheritorModel.fields) {
                fields.push(f);
            }
        }
        for(let field of fields) {
            if(field.type == 'media') {
                console.log('MEDIA', obj[field.apiName]);
            } else if(field.type == 'medialist') {
                // do nothing
            } else if(field.type == 'referencelist') {
                if(obj[field.apiName]) {
                    let serialized = [];
                    for(let ref of obj[field.apiName]) {
                        let model = this.contentModelService
                            .contentModelById(field.config.referenceId);
                        if(model) {
                            serialized.push(this.serializeContent(ref, model));
                        }
                    }
                    obj[field.apiName] = serialized;
                }
            } else if(field.type == 'reference') {
                if(obj[field.apiName] && field.config.referenceMode != 'select') {
                    let model = this.contentModelService
                        .contentModelById(field.config.referenceId);
                    if(model) {
                        obj[field.apiName] = this.serializeContent(obj[field.apiName], model);
                    }
                }
            }
        }
        return obj;
    }

    delete(apiName: string, content: Content): Observable<boolean> {
        return this.http.delete(this.endpoint + '/api/' + apiName + '/' + content.id + '?XDEBUG_SESSION_START=phpstorm').pipe(
            switchMap(result => of(true)),
            catchError(error => of(false))
        );
    }
}
