import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {NgxMatDateFormats, NgxMatDatetimePickerModule, NgxMatTimepickerModule} from '@angular-material-components/datetime-picker';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {ContentComponent} from './content/content.component';
import {ContentModelFieldDialogComponent} from './content-model-field-dialog/content-model-field-dialog.component';
import {IconSelectorComponent} from './icon-selector/icon-selector.component';
import {MediaDialogComponent} from './media-dialog/media-dialog.component';
import {MatMenuModule} from '@angular/material/menu';
import {ContentModule} from './content/content.module';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatIconModule} from '@angular/material/icon';
import {MatTabsModule} from '@angular/material/tabs';
import {MatDialogModule} from '@angular/material/dialog';
import {ServicesModule} from '../service/services.module';

@NgModule({
    declarations: [ContentModelFieldDialogComponent, IconSelectorComponent, MediaDialogComponent],
    imports: [
        CommonModule,
        FormsModule,
        MatInputModule,
        MatDatepickerModule,
        NgxMatTimepickerModule,
        NgxMatDatetimePickerModule,
        MatSlideToggleModule,
        MatMenuModule,
        MatProgressBarModule,
        MatIconModule,
        MatTabsModule,
        MatDialogModule,
        ServicesModule
    ],
    providers: [
        ContentModule
    ]
})
export class ComponentsModule { }
