import {Input} from '@angular/core';
import ContentStructureField from '../api/model/contentStructureField';

export abstract class FormBaseComponent {
    @Input() content;
    @Input() field: ContentStructureField;

    public getFieldConfigValue(key: string) {
        for(let config of this.field.config) {
            if(config.key == key) {
                return config.value;
            }
        }
        return null;
    }
}
