import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ConfigFormBaseComponent} from '../config-form-base.component';
import {BehaviorSubject} from 'rxjs';
import ContentStructure from '../../api/model/contentStructure';
import {ContentModelService} from '../../api/contentmodel/contentmodel.service';

@Component({
  selector: 'app-referencelist',
  templateUrl: './referencelist.component.html',
  styleUrls: ['./referencelist.component.scss']
})
export class ReferencelistComponent extends ConfigFormBaseComponent implements AfterViewInit{
  private contentModelsSubject = new BehaviorSubject<ContentStructure[]>([]);
  public contentModels$ = this.contentModelsSubject.asObservable();

  public configFieldReference;
  public modeFieldReference;

  public constructor(private contentModelService: ContentModelService) {
    super();
    this.contentModelService.list('').subscribe(result => {
      this.contentModelsSubject.next(result.items);
    });
  }

  ngAfterViewInit(): void {
    this.configFieldReference = this.getConfigField('referenceId');
    this.modeFieldReference = this.getConfigField('mode');
  }
}
