import ContentStructureField from '../api/model/contentStructureField';
import ContentStructureFieldConfig from '../api/model/contentStructureFieldConfig';
import {OnInit} from '@angular/core';

export abstract class ConfigFormBaseComponent implements OnInit {
    public field: ContentStructureField;
    public initialValues = {};

    public ngOnInit() {
        if(this.field) {
            for(let config of this.field.config) {
                this.initialValues[config.key] = config.value;
            }
        }
    }


    public updateConfigField(name, value) {
        console.log('value', value);
        console.log(this.field.config);
        for(const config of this.field.config) {
            if(config.key == name) {
                config.value = value;
                return;
            }
        }

        this.field.config.push({
            key: name,
            value: value
        });

    }

    public toggleConfigField(name) {
        for(const config of this.field.config) {
            if(config.key == name) {
                config.value = !config.value;
                return;
            }
        }

        this.field.config.push({
            key: name,
            value: true
        });
    }

    public getConfigFieldValue(name, defaultValue = null) {
        for(const config of this.field.config) {
            if (config.key == name) {
                console.log('MATCH', name, config.value);
                return config.value;
            }
        }
        return defaultValue;
    }

    public getConfigField(name, defaultValue = null): ContentStructureFieldConfig {
        for(const config of this.field.config) {
            if (config.key == name) {
                return config;
            }
        }
        return { key: name, value: defaultValue };
    }

}
