import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable, of} from 'rxjs';
import ResultList from '../model/resultlist';
import Helper from '../../util/helper';
import User from '../model/user';
import UserUpdate from "../model/userUpdate";
import {catchError, switchMap} from "rxjs/operators";

@Injectable()
export default class UserService {
    constructor(private http: HttpClient)
    {
    }

    list(filter?: string, sortBy?: string, sortDir?: 'asc'|'desc', page = 1, limit = 10): Observable<ResultList<User>> {
        let query = Helper.serialize({page, limit});
        return this.http.get<ResultList<User>>(environment.cmsUrl + '/api/users' + '?' + query);
    }

    show(id: string) {

    }

    delete(user: User) {

    }

    updateSettings(settings: UserUpdate): Observable<boolean> {
        return this.http.put<any>(environment.cmsUrl + '/api/users/me', settings).pipe(
            switchMap(() => of(true)),
            catchError(() => of(false))
        );
    }
}
