import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import ResultList from '../model/resultlist';
import Page from '../model/page';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {catchError, switchMap} from 'rxjs/operators';

@Injectable()
export default class PageService {
  public constructor(private http: HttpClient) {
  }
  public list(query: string, page?: number, limit?: number, additional?: any): Observable<ResultList<Page>> {
    return this.http.get<ResultList<Page>>(environment.cmsUrl + '/api/pages');
  }

  public create(page: Page): Observable<Page> {
    return this.http.post<Page>(environment.cmsUrl + '/api/pages', page);
  }

  public show(id: number): Observable<Page> {
    return this.http.get<Page>(environment.cmsUrl + '/api/pages/' + id);
  }

  public delete(page: Page) {
    return this.http.delete<boolean>(environment.cmsUrl + '/api/pages/' + page.id).pipe(switchMap(r => of(true)), catchError(r => of(false)));
  }
}
