import { Component, OnInit } from '@angular/core';
import {ConfigFormBaseComponent} from '../config-form-base.component';

@Component({
  selector: 'app-medialist',
  templateUrl: './medialist.component.html',
  styleUrls: ['./medialist.component.scss']
})
export class MedialistComponent extends ConfigFormBaseComponent {
}
