import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'nl2br'})
export class Nl2BrPipe implements PipeTransform {
  transform(value: string, args?: string[]): any {
    if (!value) {
      return value;
    }
    const breakTag = '<br>';
    return (value + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1'+ breakTag +'$2');
  }
}
