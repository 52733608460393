<mat-sidenav-container class="h-screen">
  <mat-sidenav #sidenav [mode]="mode" [opened]="opened" (closed)="opened=false">
    <div class="sidebar">
      <div [innerHTML]="title" class="w-full h-16 text-center py-3 leading-10 cursor-pointer sidebar-header" [routerLink]="['/dashboard']"></div>
      <div class="w-full mb-12 pl-12 pr-10 relative">
        <div class="w-8 h-8 rounded-full absolute vertical-center left-0 ml-4 bg-white"></div>
        <div class="text-white text-xs pl-4 py-3" *ngIf="(user$ | async) as user">
          Eingeloggt als {{ user.displayName || user.username }}<br>
        </div>
        <div class="absolute right-0 mr-4 vertical-center text-xl">
          <div class="opacity-25 hover:opacity-75 cursor-pointer" (click)="logout()" title="Ausloggen">
            <span class="iconify" data-icon="fa-solid:power-off"></span>
          </div>

        </div>
      </div>



      <mat-menu #spacesMenu="matMenu">
        <div class="w-full pr-10 pl-10 relative py-2 px-3 hover:bg-gray-100 cursor-pointer" (click)="setSelectedSpace(space)" *ngFor="let space of (spaces$ | async)">
            {{ space.name }}
            <div class="text-xs text-gray-600 truncate" [innerText]="space.description"></div>
            <div (click)="editWorkspace(space)" class="absolute vertical-center right-0 text-gray-600 hover:text-blue-500 mr-3 text-xl" title="Einstellungen">
              <span class="iconify inline" data-icon="material-symbols:settings-outline"></span>
            </div>
        </div>
        <div (click)="addSpace()" mat-menu-item>
          <span class="iconify inline-block mr-1" data-icon="fa:plus"></span> Workspace hinzufügen
        </div>
      </mat-menu>

      <div *ngFor="let group of (navigationEntryGroups$ | async)">
        <div class="menu-section">
          {{ group.label }}
        </div>
        <div *ngFor="let entry of group.entries">
          <div [routerLink]="[entry.href]" (click)="navClicked()" class="nav-entry" matRipple>
            <div class="icon">
              <span class="iconify" [attr.data-icon]="entry.icon"></span>
            </div>
            {{ entry.label }}
          </div>
        </div>
      </div>

      <div [routerLink]="['/', 'translations']" (click)="navClicked()" class="nav-entry" matRipple>
        <div class="icon">
          <span class="iconify" data-icon="icon-park-outline:chart-graph"></span>
        </div>
        Übersetzungen
      </div>

      <div class="menu-section" *ngIf="isWorkspaceAdmin">
        Administration
      </div>

      <div *ngIf="isWorkspaceAdmin" [routerLink]="['/', 'content-models']" (click)="navClicked()" class="nav-entry" matRipple>
        <div class="icon">
          <span class="iconify" data-icon="icon-park-outline:chart-graph"></span>
        </div>
        Modelle
      </div>

      <div *ngIf="isWorkspaceAdmin" [routerLink]="['/', 'users']" (click)="navClicked()" class="nav-entry" matRipple>
        <div class="icon">
          <span class="iconify" data-icon="akar-icons:person"></span>
        </div>
        Benutzer
      </div>

      <div *ngIf="isWorkspaceAdmin" [routerLink]="['/', 'roles']" (click)="navClicked()" class="nav-entry" matRipple>
        <div class="icon">
          <span class="iconify" data-icon="akar-icons:key"></span>
        </div>
        Rollen & Rechte
      </div>

      <div *ngIf="isWorkspaceAdmin" [routerLink]="['/', 'api-settings']" (click)="navClicked()" class="nav-entry" matRipple>
        <div class="icon">
          <span class="iconify" data-icon="fluent:clipboard-code-24-regular"></span>
        </div>
        API-Einstellungen
      </div>

      <div class="menu-section">
        Konto
      </div>

      <div [routerLink]="['/', 'settings']" (click)="navClicked()" class="nav-entry" matRipple>
        <div class="icon">
          <span class="iconify" data-icon="ci:settings"></span>
        </div>
        Einstellungen
      </div>

    </div>
  </mat-sidenav>

  <mat-sidenav-content class="bg-blue-50 pt-16">
    <nav class="navbar">
      <div class="flex h-full">
        <div class="nav-entry md:hidden text-3xl text-secondary" (click)="opened = !opened">
          <span class="iconify inline" data-icon="ci:menu-alt-03"></span>
        </div>
        <div class="nav-entry" matRipple [matMenuTriggerFor]="spacesMenu">
          <div class="relative pl-10 pr-8">
            <div class="inline-block text-lg absolute vertical-center left-0 ml-4">
              <span class="iconify" data-icon="jam:universe"></span>
            </div>
            <div class="text-sm" *ngIf="(selectedSpace$ | async) as selectedSpace">
              {{ selectedSpace.name }}
            </div>
            <div class="text-sm" *ngIf="(selectedSpace$ | async) == null">
              Welt wählen
            </div>
            <div class="absolute vertical-center right-0 mr-2 text-xs">
              <span class="iconify" data-icon="akar-icons:chevron-down"></span>
            </div>
          </div>

        </div>

      </div>
    </nav>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
