import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "src/environments/environment";
import {Observable, of} from "rxjs";
import {catchError, switchMap} from "rxjs/operators";
import ModelHook from '../model/modelhook';
import Helper from '../../util/helper';
import ResultList from '../model/resultlist';
import ContentStructure from '../model/contentStructure';

@Injectable()
export class ModelHookService
{
    private endpoint: string;
    private hooks: ModelHook[];

    constructor(private http: HttpClient)
    {
        this.endpoint = environment.cmsUrl;
        let query = Helper.serialize({page: 1, limit: 99});
        this.http.get<ResultList<ModelHook>>(this.endpoint + '/api/hooks?' + query).subscribe(result => {
            this.hooks = result.items;
        });
    }

    list(filter: string, sortBy: string, sortDir: 'asc'|'desc', page = 1, limit = 10): Observable<ResultList<ModelHook>> {
        let query = Helper.serialize({page, limit});
        return this.http.get<ResultList<ModelHook>>(this.endpoint + '/api/hooks?' + query);
    }

    show(id: number): Observable<ModelHook> {
        return this.http.get<ModelHook>(this.endpoint + '/api/hooks/' + id);
    }

    update(modelHook: ModelHook): Observable<boolean> {
        return this.http.post<any>(this.endpoint + '/api/hooks/' + modelHook.id, modelHook)
            .pipe(switchMap(result => of(true)), catchError(err => of(false)));
    }

    create(modelHook: ModelHook): Observable<ModelHook> {
        return this.http.post<ModelHook>(this.endpoint + '/api/hooks', modelHook);
    }

    delete(modelHook: ModelHook): Observable<boolean> {
        return this.http.delete(this.endpoint + '/api/hooks/' + modelHook.id).pipe(
            switchMap(result => of(true)),
            catchError(error => of(false))
        );
    }

    hooksByContentModel(contentModel: ContentStructure): ModelHook[] {
        let hooks = [];
        for(let h of this.hooks) {
            if(h.contentModel.apiName == contentModel.apiName) {
                hooks.push(h);
            }
        }
        return hooks;
    }

    hookById(id: number): ModelHook|null {
        for(let h of this.hooks) {
            if(h.id == id) {
                return h;
            }
        }
        return null;
    }
}
