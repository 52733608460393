import { Component, OnInit } from '@angular/core';
import {FormBaseComponent} from '../form-base.component';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent extends FormBaseComponent {

}
