<mat-label>Maximallänge</mat-label>
<mat-form-field>
    <input [value]="getConfigFieldValue('maxLength')" (change)="updateConfigField('maxLength', $event.target.value)" type="number" step="1"  matInput
           [autocomplete]="'off'"
           [placeholder]="'Maximallänge'">

</mat-form-field>

<mat-label>Platzhalter</mat-label>
<mat-form-field>
    <input [value]="getConfigFieldValue('placeholder')" (change)="updateConfigField('placeholder', $event.target.value)" type="text"  matInput
           [placeholder]="'Platzhaltertext'">

</mat-form-field>
