import {Component, Inject, OnInit} from '@angular/core';
import Workspace from '../../../vendor/ng-lion-cms/api/model/workspace';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';
import WorkspaceService from '../../../vendor/ng-lion-cms/api/space/workspace.service';
import LanguageService from '../../../vendor/ng-lion-cms/service/language.service';

@Component({
  selector: 'app-edit-space',
  templateUrl: './edit-space.component.html',
  styleUrls: ['./edit-space.component.scss']
})
export class EditSpaceComponent implements OnInit {
  public space: Workspace;
  public form: FormGroup;

  private savingSubject = new BehaviorSubject<boolean>(false);
  public saving$ = this.savingSubject.asObservable();

  public availableLanguages: string[];

  constructor(public dialogRef: MatDialogRef<EditSpaceComponent>,
              private fb: FormBuilder, private languageService: LanguageService,
              private workspaceService: WorkspaceService,
              @Inject(MAT_DIALOG_DATA) public data: EditSpaceDialogModel) { }

  ngOnInit(): void {
    this.space = this.data.space;
    this.availableLanguages = this.languageService.getEnabledLanguages();

    this.form = this.fb.group({
      name: [this.space.name, [Validators.required]],
      pagebuilder: [this.space.pagebuilder, []],
      description: [this.space.description, []],
      host: [this.space.host, []],
      image: [this.space.image, []],
      languages: [this.space.languages, []]
    });
  }

  onConfirm() {
    if(this.savingSubject.getValue()) { return; }
    if(this.form.valid) {
      const data = this.form.getRawValue();
      if(!data.host.startsWith('http')) {
        data.host = 'https://' + data.host;
      }

      this.savingSubject.next(true);
      if(this.space.id) {
        data['id'] = this.space.id;
        this.workspaceService.update(data).subscribe(result => {
          this.savingSubject.next(false);
          this.dialogRef.close(data);
        }, error => {
          this.savingSubject.next(false);
        });
      } else {
        this.workspaceService.create(data).subscribe(result => {
          this.savingSubject.next(false);
          this.dialogRef.close(result);
        }, error => {
          this.savingSubject.next(false);
        });
      }


    }

  }

  onDismiss() {
    this.dialogRef.close(null);
  }

}

export class EditSpaceDialogModel {
  public constructor(public space: Workspace) {
  }
}
