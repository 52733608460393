import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {environment} from '../environments/environment';
import {BaseComponent} from './base/base.component';
import {InitialDataResolver} from './app.resolver';

let routes: Routes = [];

routes = [
  {path: 'auth', loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule)},
  { path: 'invitation', loadChildren: () => import('./pages/invitation/invitation.module').then(m => m.InvitationModule) },
  {
    resolve: {
      initialData: InitialDataResolver,
    },
    path: '',
    component: BaseComponent,
    children: [
      {path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {path: 'dashboard', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule)},
      {path: 'spaces', loadChildren: () => import('./pages/space/space.module').then(m => m.SpaceModule)},
      {path: 'api-settings', loadChildren: () => import('./pages/api-settings/api-settings.module').then(m => m.ApiSettingsModule)},
      {path: 'roles', loadChildren: () => import('./pages/security/security.module').then(m => m.SecurityModule)},
      {path: 'users', loadChildren: () => import('./pages/user/user.module').then(m => m.UserModule)},
      {path: 'settings', loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsModule)},
      {path: 'translations', loadChildren: () => import('./pages/translations/translations.module').then(m => m.TranslationsModule)},
      {path: 'content-models', loadChildren: () => import('./pages/content-model/content-model.module').then(m => m.ContentModelModule)},
      {path: 'pages', loadChildren: () => import('./pages/page/page.module').then(m => m.PageModule)},
      {path: 'medialib', loadChildren: () => import('./pages/medialib/medialib.module').then(m => m.MedialibModule)},
      {path: 'm', loadChildren: () => import('./pages/content/content.module').then(m => m.ContentModule)},
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
