import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export default class LanguageService {
    private languageSubject = new BehaviorSubject<string>('de');
    public language$ = this.languageSubject.asObservable();

    public constructor() {
        this.languageSubject.next(this.loadActiveLanguage());
    }
    private loadActiveLanguage() {
        return localStorage.getItem('lang') || 'de';
    }

    public getActiveLanguageSnapshot() {
        return this.languageSubject.getValue();
    }

    public setActiveLanguage(lang: string) {
        if(this.getEnabledLanguages().indexOf(lang) >= 0) {
            localStorage.setItem('lang', lang);
            this.languageSubject.next(lang);
        }
    }

    public getEnabledLanguages() {
        return ['de', 'en', 'fr', 'es'];
    }
}
