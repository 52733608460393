<label>{{ field.name }}</label>
<div *ngIf="getFieldConfigValue( 'mode') == 'popup'; else referenceListInline">
    <div class="font-italic py-2 text-gray-600" *ngIf="!content[field.apiName] || !content[field.apiName].length">
        Klicke auf Hinzufügen, um neue Einträge hinzuzufügen.
    </div>
    <div matRipple (click)="openModal(referenceDialog, reference)" class="bg-white py-2 px-4 shadow cursor-pointer rounded mb-4" *ngFor="let reference of content[field.apiName]">
        <div class="w-full flex flex-wrap">
            <div class="w-full md:w-1/2">
                <div class="text-gray-300" *ngIf="reference.inheritorModel != null">
                    {{ reference.inheritorModel.name }}
                </div>
            </div>
            <div class="w-full md:w-1/2 md:text-right">
                <a *ngIf="getFieldConfigValue('sortable')" (click)="moveDown(field.apiName, reference)"
                   class="text-sm cursor-pointer hover:underline text-gray-500 py-2 px-2 rounded-sm">Nach unten</a>
                <a *ngIf="getFieldConfigValue( 'sortable')" (click)="moveUp(field.apiName, reference)"
                   class="text-sm cursor-pointer hover:underline text-gray-500 py-2 px-2 rounded-sm">Nach oben</a>
                <a (click)="removeReference(field.apiName, reference)"
                   class="text-sm cursor-pointer hover:underline text-red-600 py-2 px-2 rounded-sm">Entfernen</a>
            </div>
        </div>
    </div>

    <ng-template #referenceDialog>
        <div mat-dialog-content>
            <app-content [content]="selectedReference" [referenceId]="getFieldConfigValue( 'referenceId')"></app-content>
        </div>
        <div mat-dialog-actions>
            <div class="w-full text-right pt-2">
                <div mat-dialog-close="" matRipple class="py-2 px-4 font-bold bg-gray-200 text-gray-700 inline-block rounded cursor-pointer">
                    Schließen
                </div>
            </div>
        </div>
    </ng-template>
</div>
<ng-template #referenceListInline>
    <div class="font-italic py-2 text-gray-600" *ngIf="!content[field.apiName] || !content[field.apiName].length">
        Klicke auf Hinzufügen, um neue Einträge hinzuzufügen.
    </div>
    <div class="bg-white py-2 px-4 shadow rounded mb-4" *ngFor="let reference of content[field.apiName]">
        <div class="w-full flex flex-wrap">
            <div class="w-full md:w-1/2">
                <div class="text-gray-300" *ngIf="reference.inheritorModel != null">
                    {{ reference.inheritorModel.name }}
                </div>
            </div>
            <div class="w-full md:w-1/2 md:text-right">
                <a *ngIf="getFieldConfigValue( 'sortable')" (click)="moveDown(field.apiName, reference)"
                   class="text-sm cursor-pointer hover:underline text-gray-500 py-2 px-2 rounded-sm">Nach unten</a>
                <a *ngIf="getFieldConfigValue( 'sortable')" (click)="moveUp(field.apiName, reference)"
                   class="text-sm cursor-pointer hover:underline text-gray-500 py-2 px-2 rounded-sm">Nach oben</a>
                <a (click)="removeReference(field.apiName, reference)"
                   class="text-sm cursor-pointer hover:underline text-red-600 py-2 px-2 rounded-sm">Entfernen</a>
            </div>
        </div>
        <app-content [content]="reference" [referenceId]="getFieldConfigValue( 'referenceId')"></app-content>
    </div>

</ng-template>



<div class="flex flex-wrap" *ngIf="getFieldConfigValue( 'referenceId') as config">
    <div class="w-full" *ngIf="getContentModel(config) as contentModel">
        <div *ngIf="contentModel.abstract" class="w-full px-2">
            <label>Typ</label>
            <select class="styled" [(ngModel)]="selectedContentModel">
                <option [value]="inheritor" *ngFor="let inheritor of contentModel.inheritors">
                    {{ inheritor.name }}
                </option>
            </select>
        </div>
    </div>
    <div class="w-full mt-3">
        <div (click)="addReference(field)" class="btn primary sm" matRipple>
            Hinzufügen
        </div>
    </div>
</div>
