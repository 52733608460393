import { serialize as formDataSerializer } from 'object-to-formdata';

export default class Helper {
    public static serialize(obj, prefix?) {
        var str = [],
            p;
        for (p in obj) {
            if (obj.hasOwnProperty(p)) {
                var k = prefix ? prefix + "[" + p + "]" : p,
                    v = obj[p];
                str.push((v !== null && typeof v === "object") ?
                    this.serialize(v, k) :
                    encodeURIComponent(k) + "=" + encodeURIComponent(v));
            }
        }
        return str.join("&");
    }

    public static cartesianProduct(a) { // a = array of array
        var i, j, l, m, a1, o = [];
        if (!a || a.length == 0) return a;

        a1 = a.splice(0, 1)[0]; // the first array of a
        a = this.cartesianProduct(a);
        for (i = 0, l = a1.length; i < l; i++) {
            if (a && a.length) for (j = 0, m = a.length; j < m; j++)
                o.push([a1[i]].concat(a[j]));
            else
                o.push([a1[i]]);
        }
        return o;
    }

    public static slugify(text: string) {
        return text
            .toString()
            .normalize( 'NFD' )                   // split an accented letter in the base letter and the acent
            .replace( /[\u0300-\u036f]/g, '' )   // remove all previously split accents
            .toLowerCase()
            .trim()
            .replace(/\s+/g, '-')
            .replace(/[^\w\-]+/g, '')
            .replace(/\-\-+/g, '-');
    };

    public static objectToFormData(object: any): FormData {
        return formDataSerializer(object);
    }
}

