<label>{{ field.name }}</label>

<div class="styled" (click)="picker.open()" tabindex="0">
    {{ content[field.apiName] ? (content[field.apiName] | date: 'dd.MM.yyyy HH:mm') : 'Bitte wählen' }}
</div>
<input #input (dateChange)="setDate($event.value)" (focus)="picker.open()" class="input -mt-4" type="text" [ngxMatDatetimePicker]="picker" placeholder="Bitte wählen"
       [min]="minDate" [max]="maxDate">
<ngx-mat-datetime-picker #picker [showSpinners]="true" [showSeconds]="false"
                         [stepHour]="1" [stepMinute]="1" [stepSecond]="1"
                         [touchUi]="false" [hideTime]="false">
</ngx-mat-datetime-picker>
